import React, { useState } from "react";
import { Modal, Button, Form, Table, Row, Col} from "react-bootstrap";
import { translationOsmValues } from "../dashboard/functions/Translations";

const FilterComponent = ({ filters, setFilters, filterModalOpen, setFilterModalOpen, entries, setEntries }) => {
  const [filterRules, setFilterRules] = useState([]);
  const [activeRuleIndex, setActiveRuleIndex] = useState(null);
  

   // Übersetzungsfunktionsreferenz
   const translations = translationOsmValues();

   const translateValue = (category, value) => {
     if (!value || value === '-') return '-';
     return translations[category] && translations[category][value] ? translations[category][value] : value;
   };

  const translateCategory = (category) => {
    const categoryTranslations = {
      highway: "Wegtyp",
      surface: "Oberfläche",
      cycleway: "Führungsform",
      maxspeed: "Tempolimit",
     // width:"Radwegebreite",
      rcn:"Regionale Radroute",
      bicycle_road: "Fahrradstraße"
    };
    return categoryTranslations[category] || category;
  };

  const addFilterRule = () => {
    setFilterRules([...filterRules, { category: '', selectedEntries: [] }]);
    setActiveRuleIndex(filterRules.length);
  };

  const handleCategoryChange = (index, value) => {
    const newRules = [...filterRules];
    newRules[index].category = value;
    newRules[index].selectedEntries = [];
    setFilterRules(newRules);
  };

  const handleEntryChange = (index, entry) => {
    const newRules = [...filterRules];
    const selectedEntries = newRules[index].selectedEntries;

    if (selectedEntries.includes(entry)) {
      newRules[index].selectedEntries = selectedEntries.filter(e => e !== entry);
    } else {
      newRules[index].selectedEntries.push(entry);
    }

    setFilterRules(newRules);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const applyFilters = () => {
    const newFilters = { ...filters };
    filterRules.forEach(rule => {
      if (rule.category) {
        newFilters[rule.category] = rule.selectedEntries.join(', ');
      }
    });
    setFilters(newFilters);
    console.log('Applied Filters:', newFilters);
    handleFilterModalClose();
  };

  const matchesFilter = (entry) => {
    for (const category in filters) {
      if (filters[category]) {
        const filterValues = filters[category].split(', ').map(value => value.trim());
        console.log(`Checking category: ${category}, Entry value: ${entry[category]}, Filter values: ${filterValues}`);
        if (!filterValues.includes(entry[category])) {
          return false;
        }
      }
    }
    return true;
  };

  const handleFilterApproval = () => {
    setActiveRuleIndex(null);
  };

  const resetCategory = (index) => {
    const newRules = filterRules.filter((_, i) => i !== index);
    setFilterRules(newRules);

    const categoryToRemove = filterRules[index].category;
    if (categoryToRemove) {
      const newFilters = { ...filters };
      delete newFilters[categoryToRemove];
      setFilters(newFilters);
    }

    if (activeRuleIndex === index) {
      setActiveRuleIndex(null);
    }
  };

  return (
    <Modal
      show={filterModalOpen}
      onHide={handleFilterModalClose}
      size="xl"
      centered
      className="modal-report-form"
    >
      <Modal.Header closeButton>
        <Modal.Title>Segmente filtern</Modal.Title>
      </Modal.Header>
          <Modal.Body className="filterModal">
          <Form>
            <Row className="selectedFilter">
              <Table>
                <tbody className="tableFilter">
                  {filterRules.map((rule, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: '1.4em', color: '#6c9c34' }} lg={2}>
                        <strong>{translateCategory(rule.category)}:</strong>
                      </td>
                      <td style={{ fontSize: '1.2em', color: 'grey' }} lg={9}>
                        {rule.selectedEntries.length > 0
                          ? rule.selectedEntries.map(entry => translations[rule.category][entry]).join(', ')
                          : "kein Filter"}                    </td>
                      <td lg={1}>
                        <Button className="filterSegBtn" variant="secondary" onClick={() => setActiveRuleIndex(index)}>
                          Bearbeiten
                        </Button>
                        <Button className="filterSegBtn" variant="danger" onClick={() => resetCategory(index)}>
                          X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            {filterRules.map((rule, index) => (
              <Form.Group key={index} controlId={`filterRule${index}`}>
                {activeRuleIndex === index && (
                  <>
                    <Row>
                      <Col className="filtertitle" lg={2}>
                        <p className="filtertitleElement">Kategorie: </p>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          as="select"
                          value={rule.category}
                          onChange={(e) => handleCategoryChange(index, e.target.value)}
                          className="formcontrolFilter"
                        >
                          <option value="">Wählen Sie eine Kategorie</option>
                          <option value="highway">Wegtyp</option>
                          <option value="surface">Oberfläche</option>
                          <option value="cycleway">Führungsform</option>
                          <option value="maxspeed">Tempolimit</option>
                          <option value="rcn">regionale Radroute</option>
                          <option value="bicycle_road">Fahrradstraße</option>
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="filterSelection">
                      <Col lg={12}>
                        {rule.category && (
                          <>
                            <Row className="checkboxFilter">
                              {Object.entries(translations[rule.category]).map(([key, displayValue], idx) => (
                                <Col lg={4} key={key}>
                                  <Form.Check
                                    type="checkbox"
                                    className="segmentfiltercheck"
                                    id={`entry-${index}-${key}`}
                                    label={displayValue}
                                    checked={rule.selectedEntries.includes(key)}
                                    onChange={() => handleEntryChange(index, key)}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row className="filterApproval">
                      <Button variant="primary" onClick={() => handleFilterApproval(index)}>
                        Eingabe bestätigen
                      </Button>
                    </Row>
                  </>
                )}
              </Form.Group>
            ))}

          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Row className="w-100">
            <Col lg={6} className="d-flex justify-content-center">
              <Button variant="primary" className="w-100" onClick={addFilterRule}>
                Regel hinzufügen
              </Button>
            </Col>
            <Col lg={6} className="d-flex justify-content-center">
              <Button variant="primary" className="w-100" onClick={applyFilters}>
                Segmente filtern
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

  );
};

export default FilterComponent;