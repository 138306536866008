import React, { useCallback, useEffect } from "react";
import { LayerGroup, LayersControl, Polyline, Tooltip } from "react-leaflet";
import { fetchAccidentCount, fetchTrafficCount, fetchAccidentSegmentCount, fetchAccidentRateSegmentCount, fetchConcernsSegmentCount, fetchRoadDamageSegmentCount, fetchAccidentNodeCount } from "../../functions/MapFunctions";
import { getColorClassAccidents, getColorClassSegmentAccidents, getColorClassSegmentSpeed, getLegendColor } from "../../functions/Colors";
import { debounce } from 'lodash';

function InfrastructureLayer({ scoreStateInfrastructure, infrastructureChecked, infrastructureData, mapBounds, updateInfrastructureData,
    accidentRoadData,accidentRateData, trafficRoadData, accidentNodeData, mergedRoadData, concernRoadData, roadDamageData,
    updateConcernsRoadData, updateMergedRoadData, updateAccidentRoadData, updateAccidentRateData, updateRoadDamageData,
    updateTrafficRoadData, updateAccidentNodeData, updateLoadingSpin, sD, eD, town }) {

     

    const useInfrastructure = useCallback(() => {
        if (scoreStateInfrastructure === "Unfall") {
            fetchAccidentCount(updateMergedRoadData, updateLoadingSpin, sD, eD);
        }
        else if (scoreStateInfrastructure === "Unfälle") {
            fetchAccidentSegmentCount(updateAccidentRoadData, mapBounds, updateLoadingSpin, sD, eD);
        }
        else if (scoreStateInfrastructure === "Meldungen") {
            fetchConcernsSegmentCount(updateConcernsRoadData, mapBounds, updateLoadingSpin, sD, eD);
        }
        else if (scoreStateInfrastructure === "Schadensmeldungen") {
            fetchRoadDamageSegmentCount(updateRoadDamageData, mapBounds, updateLoadingSpin, sD, eD);
        }
        else if (scoreStateInfrastructure === "Verkehrsaufkommen" || scoreStateInfrastructure === "Geschwindigkeit") {
            fetchTrafficCount(updateTrafficRoadData, mapBounds, updateLoadingSpin, sD, eD);
        }
        else if (scoreStateInfrastructure === "Unfälle per Knoten") {
            fetchAccidentNodeCount(updateAccidentNodeData, mapBounds, updateLoadingSpin, sD, eD);
        }
        if (scoreStateInfrastructure === "Unfallrate") {
            console.log(accidentRateData);
            fetchAccidentRateSegmentCount(updateAccidentRateData, mapBounds, updateLoadingSpin, sD, eD, town) ;
        }
    }, [mapBounds, eD, sD, town]);

    const debouncedUseInfrastructure = useCallback(debounce(() => {
        function getCities() {
            return {
                "oldenburg": "Oldenburg",
                "osnabrueck": "Osnabrück",
                "Oldenburg": "oldenburg",
                "Osnabrück": "osnabrueck"
            };
        }
        
        if (infrastructureChecked) {
            const cityMap = getCities();
        town = cityMap[town] || town; 
            useInfrastructure();
        }
    }, 200), [infrastructureChecked, useInfrastructure]);

    useEffect(() => {
        debouncedUseInfrastructure();
        return () => {
            debouncedUseInfrastructure.cancel();
        };
    }, [infrastructureChecked, scoreStateInfrastructure, mapBounds, eD, sD, debouncedUseInfrastructure, town]);


    return (
        <>
            <LayersControl.Overlay name="14: Kumulierte Segmentwerte" checked={infrastructureChecked}>
                <LayerGroup>

                    {/* 
                    {scoreStateInfrastructure === "Steigung" && Object.keys(infrastructureData).map((i) => {
                        const road = infrastructureData[i];
                        if (road.slope === null) {
                            road.slope === 0;
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getColorClassElevation(road.slope)}
                                weight={2}
                                fill={false}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="left"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br />
                                    {"Steigung: " + (road.slope ? road.slope.toFixed(2) + "%" : "k.A")}<br />
                                </Tooltip>
                            </Polyline>
                        );
                    })}
*/}

                    {/* Unfallrate je Straße anzeigen */}
                    {scoreStateInfrastructure === "Unfallrate" && Object.keys(accidentRateData).map((station) => {
                        const road = accidentRateData[station];
          
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }

                        return (
                            <Polyline
                                key={road.stationname}
                                positions={road.geometry.map(coord => [coord[1], coord[0]])}
                                color={getLegendColor(road.unfallrate, road.max)}
                                weight={3}
                                fill={false}
                                opacity={0.7}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    <strong>{"Straße: " + (road.stationname ? road.stationname : "k.A.")}</strong><br />
                                    <strong>{"Unfallrate: " + (road.unfallrate ? road.unfallrate.toFixed(2) : 0)}</strong><br />
                                    <strong>{"Unfälle: " + (road.accidentsCount ? road.accidentsCount : 0)}</strong><br />
                                    <strong>{"Länge in m: " + (road.length ? road.length.toFixed(2) : 0)}</strong><br />
                                </Tooltip>
                            </Polyline>
                        );
                    })}


                    {/* Unfälle je Straßensegment anzeigen */}
                    {scoreStateInfrastructure === "Unfälle" && Object.keys(accidentRoadData).map((key) => {
                        const road = accidentRoadData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline
                                key={road.segment_id}
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getColorClassAccidents(road.accident_count)}
                                weight={2}
                                fill={false}
                                opacity={0.7}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    <strong>{"gezählte Unfälle: " + (road.accident_count ? road.accident_count : 0)}</strong><br />
                                    {Object.entries(road.accident_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Unfälle je Knoten anzeigen */}
                    {scoreStateInfrastructure === "Unfälle per Knoten" && Object.keys(accidentNodeData).map((key) => {
                        const node = accidentNodeData[key];

                        if (!node || !node.geometry) {
                            return null;
                        }
                        return (
                            <Polyline
                                key={node.node_id}
                                positions={node.geometry.coordinates}
                                color="black"
                                weight={2}
                                fill={true}
                                fillColor={getColorClassAccidents(node.accident_count)}
                                fillOpacity={0.6}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    {"ID: " + (node.node_id ? node.node_id : "k.A")}<br />
                                    {"gezählte Unfälle: " + (node.accident_count ? node.accident_count : 0)}<br />
                                    {Object.entries(node.accident_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Meldungen je Straßensegment anzeigen */}
                    {scoreStateInfrastructure === "Meldungen" && Object.keys(concernRoadData).map((key) => {
                        const road = concernRoadData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline
                                key={road.segment_id}
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getColorClassSegmentAccidents(road.concern_count)}
                                weight={2}
                                fill={false}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    {"gezählte Meldungen: "}<br />
                                     {(road.concern_count ? road.concern_count : "k.A")}<br />
                                    {Object.entries(road.concern_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Schadensmeldungen je Straßensegment anzeigen */}
                    {scoreStateInfrastructure === "Schadensmeldungen" && Object.keys(roadDamageData).map((key) => {
                        const road = roadDamageData[key];
                        if (road === null || road.geometry === null) {
                            return null
                        }

                        return (
                            <Polyline
                                key={road.segment_id}
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getColorClassSegmentAccidents(road.damage_count)}
                                weight={2}
                                fill={false}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    {"gezählte Schadensmeldungen: "}<br />
                                    {(road.damage_count ? road.damage_count : 0)}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Verkehrsaufkommen je Straßensegment anzeigen */}
                    {scoreStateInfrastructure === "Verkehrsaufkommen" && Object.keys(trafficRoadData).map((key) => {
                        const road = trafficRoadData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline
                            key={`${road.segment_id}-${road.max}`} 
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getLegendColor(road.day_avg_traffic_count.toFixed(0),road.max)}
                                weight={2}
                                fill={false}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    <p className="tooltiptext">{"durchschnittliches VA der " + (road.stations) + " Zählstelle/n"}</p>
                                    <p className="tooltiptext">{(road.day_avg_traffic_count ? road.day_avg_traffic_count.toFixed(0) : "k.A") + " am Tag"}</p>
                                    <p className="tooltiptext">{(road.hour_avg_traffic_count ? road.hour_avg_traffic_count.toFixed(0) : "k.A") + " pro Stunde"}</p>
                                    <p className="tooltiptext">{(road.sum_traffic_count ? road.sum_traffic_count.toFixed(0) : "k.A") + " Summe Zeitraum"}</p>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Speed je Straßensegment anzeigen */}
                    {scoreStateInfrastructure === "Geschwindigkeit" && Object.keys(trafficRoadData).map((key) => {
                        const road = trafficRoadData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline
                                key={road.segment_id}
                                positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                color={getColorClassSegmentSpeed(road.avg_speed_count)}
                                weight={2}
                                fill={false}
                                opacity={1}
                            >
                                <Tooltip
                                    sticky={false}
                                    direction="auto"
                                    offset={[0, -5]}
                                >
                                    <p className="tooltiptext">{"Durchschnitt der " + (road.stations) + " Zählstelle/n"}</p>
                                    <p className="tooltiptext">{(road.avg_speed_count ? road.avg_speed_count.toFixed(2) : "k.A") + " km/h"}</p>

                                </Tooltip>
                            </Polyline>
                        );
                    })}


                </LayerGroup>
            </LayersControl.Overlay>
        </>
    );
}

export default InfrastructureLayer;