import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {infrastructureOptions} from "../../functions/FilterFunctions";

function FilterInfrastructure({scoreStateInfrastructure, updateScoreStateInfrastructure, updateLoadingSpin}) {

    return (
        <div>
            {infrastructureOptions.map((infrastructureType, index) => (
                <button key={index} className={`col-12 filterBtn ${scoreStateInfrastructure === infrastructureType ? "selected" : ""}`}
                        onClick={() => { updateScoreStateInfrastructure(infrastructureType); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container" >
                                <img src={scoreStateInfrastructure === infrastructureType ? checked : unchecked}
                                     alt={scoreStateInfrastructure === infrastructureType ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {infrastructureType}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterInfrastructure;