import React, { useEffect } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import { translationOsmValues } from "../dashboard/functions/Translations";


const OsmSegment = ({
  segment,
  entries,
  filters,
  mapBounds,
  setSegments,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments
}) => {

  // Übersetzungsfunktionsreferenz
  const translations = translationOsmValues();

  const translateValue = (category, value) => {
    if (!value || value === '-') return '-';
    return translations[category] && translations[category][value] ? translations[category][value] : value;
  };

  useEffect(() => {
    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;

      const overpassQuery = `
        [out:json][timeout:25];
        (
          way["highway"](${latMin},${lngMin},${latMax},${lngMax});
        );
        out tags geom;
      `;

      const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;

      fetch(overpassUrl)
        .then(response => response.json())
        .then(data => {
          const segments = data.elements.map(element => ({
            segment_id: element.id,
            name: element.tags.name || 'Name fehlt',
            geometry: element.geometry.map(coordinates => [coordinates.lat, coordinates.lon]),
            highway: element.tags.highway || '-',
            lit: element.tags.lit || '-',
            maxspeed: element.tags.maxspeed || '-',
            parking_lane_left: element.tags['parking:lane:left'] || '-',
            parking_lane_left_parallel: element.tags['parking:lane:left:parallel'] || '-',
            parking_lane_right: element.tags['parking:lane:right'] || '-',
            sidewalk: element.tags.sidewalk || '-',
            sidewalk_both_surface: element.tags['sidewalk:both:surface'] || '-',
            source_maxspeed: element.tags['source:maxspeed'] || '-',
            surface: element.tags.surface || '-',
            cycleway_surface: element.tags['cycleway:surface'] || '-',
            cycleway_width: element.tags['cycleway:width'] || '-',
            traffic_sign: element.tags.traffic_sign || '-',
            width: element.tags.width || '-',
            bicycle_road: element.tags.bicycle_road || '-',
            oneway: element.tags.oneway || '-',
            rcn: element.tags.rcn || '-'
          }));

          setSegments(segments);
        })
        .catch(error => {
          console.error("Fehler beim Laden der Segmente:", error);
        });
    }
  }, [mapBounds, setSegments]);

  const matchesFilter = (entry) => {
    for (const category in filters) {
      if (filters[category]) {
        const filterValues = filters[category].split(', ').map(value => value.trim());
        console.log(`Checking category: ${category}, Entry value: ${entry[category]}, Filter values: ${filterValues}`);
        if (!filterValues.includes(entry[category])) {
          return false;
        }
      }
    }
    return true;
  };

  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };

  return (
    <>
      {segment.map((road) => {
        if (road.geometry === undefined) {
          return null;
        }
        const positions = road.geometry;
        const isFiltered = matchesFilter(road);
        const isSelected = selectedSegments.has(road.segment_id);
        return (
          <React.Fragment key={road.segment_id}>
            {/* White polyline for the gap effect */}
            <Polyline
              key={`${road.segment_id}-white`}
              positions={positions}
              color="white"
              weight={8}
              fill={false}
              opacity={1}
            />
         
            <Polyline
              key={`${road.segment_id}-${isFiltered}-${isSelected}`}
              positions={positions}
              className="hoversegment"
              color={isSelected ? "orange" :"darkgrey" }
              weight={6}
              fill={false}
              opacity={isFiltered ? 0.8 : 0.1}
              eventHandlers={{
                click: () => handlePolylineClick(road.segment_id),
                mouseover: (e) => {
                  e.target.setStyle({ color: '#6c9c34', opacity: 1.0 });
                },
                mouseout: (e) => {
                  e.target.setStyle({ color: isSelected ? "orange" : "darkgrey", opacity: isSelected ? 1.0 : (isFiltered ? 0.8 : 0.1) });
                }
              }}
            >
                        <Tooltip
                sticky={false}
                direction="auto"
                offset={[0, -5]}
              >
                <div class="table-container">
                  <div class="table-wrapper">
                    <h4>OSM-Daten</h4>
                    <table class="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {road.segment_id && (
                          <tr>
                            <td><strong>ID</strong></td>
                            <td>{road.segment_id}</td>
                          </tr>
                        )}
                        {road.name && (
                          <tr>
                            <td><strong>Name</strong></td>
                            <td>{road.name}</td>
                          </tr>
                        )}
                        {road.highway && (
                          <tr>
                            <td><strong>Wegtyp</strong></td>
                            <td>{translateValue('highway', road.highway)}</td>
                          </tr>
                        )}
                        {road.bicycle_road && (
                          <tr>
                            <td><strong>Fahrradstraße</strong></td>
                            <td>{road.bicycle_road}</td>
                          </tr>
                        )}
                        {road.bicycle && (
                          <tr>
                            <td><strong>Rad</strong></td>
                            <td>{translateValue('bicycle', road.bicycle)}</td>
                          </tr>
                        )}
                        {road.foot && (
                          <tr>
                            <td><strong>Fußgänger</strong></td>
                            <td>{road.foot}</td>
                          </tr>
                        )}
                        {(road.surface || road.cycleway_surface) && (
                          <tr>
                            <td><strong>Oberfläche</strong></td>
                            <td>{road.surface ? translateValue('surface', road.surface) : road.cycleway_surface}</td>
                          </tr>
                        )}
                        {(road.width || road.cycleway_width) && (
                          <tr>
                            <td><strong>Wegebreite</strong></td>
                            <td>{road.width ? road.width : road.cycleway_width}</td>
                          </tr>
                        )}
                        {road.lit && (
                          <tr>
                            <td><strong>Beleuchtung</strong></td>
                            <td>{translateValue('lit', road.lit)}</td>
                          </tr>
                        )}
                        {road.traffic_sign && (
                          <tr>
                            <td><strong>Verkehrszeichen</strong></td>
                            <td>{road.traffic_sign}</td>
                          </tr>
                        )}
                        {road.maxspeed && (
                          <tr>
                            <td><strong>Tempolimit</strong></td>
                            <td>{road.maxspeed}</td>
                          </tr>
                        )} {road.oneway && (
                          <tr>
                            <td><strong>Einbahnstraße</strong></td>
                            <td>{translateValue('oneway',road.oneway)}</td>
                          </tr>
                        )}    
                        {road.rcn && (
                          <tr>
                            <td><strong>regionale Fahrradroute</strong></td>
                            <td>{translateValue('oneway',road.rcn)}</td>
                          </tr>
                        )}        
                                        </tbody>
                    </table>
                  </div>
                </div>
              </Tooltip>
            </Polyline>
          </React.Fragment>
        );
      })}
      {/* Tabellen-Komponente */}
      {entries.map((entry) => {

        if (!entry.geometry) {
          return null;
        }

        const isFiltered = matchesFilter(entry);
        const isSelected = selectedSegments.has(entry.segment_id);
        return (
          <Polyline
            key={`entry-${entry.segment_id}-${isFiltered}`}
            positions={entry.geometry}
            color={isSelected ? "orange" : (isFiltered ? "#6c9c34" : "darkgrey")}
            weight={6}
            fill={false}
            opacity={isFiltered ? 0.8 : 0.1}
            eventHandlers={{
              click: () => toggleOffcanvas(),
            }}
          >
            <Tooltip
              sticky={false}
              direction="auto"
              offset={[0, -5]}
            >
              <div class="table-container">
                <div class="table-wrapper">
                  <h4>OSM-Daten</h4>
                  <table class="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.segment_id && (
                        <tr>
                          <td><strong>ID</strong></td>
                          <td>{entry.segment_id}</td>
                        </tr>
                      )}
                      {entry.name && (
                        <tr>
                          <td><strong>Name</strong></td>
                          <td>{entry.name}</td>
                        </tr>
                      )}
                      {entry.highway && (
                        <tr>
                          <td><strong>Wegtyp</strong></td>
                          <td>{translateValue('highway', entry.highway)}</td>
                        </tr>
                      )}
                      {entry.cycleway && (
                        <tr>
                          <td><strong>Führungsform</strong></td>
                          <td>{entry.fuhrungsform ? translateValue('cycleway', entry.fuhrungsform) : translateValue('cycleway', entry.cycleway)}</td>
                        </tr>
                      )}

                      {entry.bicycle && (
                        <tr>
                          <td><strong>Für Rad</strong></td>
                          <td>{translateValue('bicycle', entry.bicycle)}</td>
                        </tr>
                      )}
                      {entry.foot && (
                        <tr>
                          <td><strong>Für Fußgänger</strong></td>
                          <td>{entry.foot}</td>
                        </tr>
                      )}
                      {entry.surface && (
                        <tr>
                          <td><strong>Oberfläche</strong></td>
                          <td>{entry.oberflaeche ? translateValue('surface', entry.oberflaeche) : translateValue('surface', entry.surface)}</td>
                        </tr>
                      )}
                      {entry.lit && (
                        <tr>
                          <td><strong>Beleuchtung</strong></td>
                          <td>{translateValue('lit', entry.lit)}</td>
                        </tr>
                      )}
                      {entry.traffic_sign && (
                        <tr>
                          <td><strong>Verkehrszeichen</strong></td>
                          <td>{entry.traffic_sign}</td>
                        </tr>
                      )}
                      {entry.maxspeed && (
                        <tr>
                          <td><strong>Tempolimit</strong></td>
                          <td>{entry.tempolimit ? entry.tempolimit : entry.maxspeed}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div class="table-wrapper">
                  <h4>Ergänzte Daten</h4>
                  <table class="tooltipTable" border="1" cellpadding="5" cellspacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.fuhrungsform && (
                        <tr>
                          <td><strong>Führungsform</strong></td>
                          <td>{entry.fuhrungsform}</td>
                        </tr>
                      )}
                      {entry.oberflaeche && (
                        <tr>
                          <td><strong>Oberfläche</strong></td>
                          <td>{entry.oberflaeche}</td>
                        </tr>
                      )}
                      {entry.tempolimit && (
                        <tr>
                          <td><strong>Tempolimit</strong></td>
                          <td>{entry.tempolimit}</td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </Tooltip>
          </Polyline>
        );
      })}
    </>
  );
};

export default OsmSegment;