import { ApiConnect } from "../Dashboard";
import { booleanPointInPolygon } from '@turf/turf';
import { point as turfPoint, lineString as turfLineString } from '@turf/helpers';
import * as turf from '@turf/turf';

export function getMapBounds(mapBounds) {
    const latMax = mapBounds.getNorthEast().lat;
    const lngMax = mapBounds.getNorthEast().lng;

    const latMin = mapBounds.getSouthWest().lat;
    const lngMin = mapBounds.getSouthWest().lng;

    return {latMax, lngMax, latMin, lngMin}
}

export function onlyRoadScoreCol(column, mapBounds, updateRoadScoreData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchOnlyRoadScoreCol(column, latMax, lngMax, latMin, lngMin)
        .then((scoreData) => {
            let scoreData_adjusted = {};

            if (scoreData !== null) {
                scoreData_adjusted = scoreData.map((element) => {
                    let coordinates = element.geometry.coordinates;
                    coordinates.forEach(function (value, i) {
                        coordinates[i] = [value[1], value[0]];
                    });
                    element.geometry.coordinates = coordinates;
                    return element;
                });
            }

            updateRoadScoreData(scoreData_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchNodesData(column, mapBounds, updateNodesData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchNodesData(column, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            let data_adjusted = {};

            if (data !== null) {
                data_adjusted = data.map((element) => {
                    const coordinates = element.geometry.coordinates[0].map(value => [value[1], value[0]]);
                    element.geometry.coordinates = [coordinates];
                    return element;
                });
            }

            updateNodesData(data_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchRoadsData(column, mapBounds, updateRoadsData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchRoadsData(column, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            let data_adjusted = {};

            if (data !== null) {
                data_adjusted = data.map((element, index) => {
                    element.geometry.coordinates = element.geometry.coordinates.map(value => [value[1], value[0]]);
                    element.id = `rs${index + 1}`;
                    return element;
                });
            }
            updateRoadsData(data_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchHeatmapData(column, mapBounds, updateData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchHeatmapData(column, latMax, lngMax, latMin, lngMin)
        .then((heatMapData) => {
            let heatMapData_adjusted = {};

            if (heatMapData !== null) {
                heatMapData_adjusted = heatMapData.map((element) => {
                    const coordinates = element.geometry.coordinates[0].map((value) => [value[1], value[0]]);
                    element.geometry.coordinates = [coordinates];
                    return element;
                });
            }

            updateData(heatMapData_adjusted);
            updateLoadingSpin(false);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function fetchBusLinesData(mapBounds, updateBusData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchBusLines(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const groupedBusData = {};

            if (data !== null) {
                data.forEach((element) => {
                    const busLineName = element.bus_line_name;
                    const busLineId = element.bus_line_id.substring(0, 5);
                    const coordinates = element.geometry.coordinates.map((coord) => [
                        coord[1],
                        coord[0],
                    ]);
                    if (groupedBusData[busLineName]) {
                        groupedBusData[busLineName].push({busLineId, coordinates});
                    } else {
                        groupedBusData[busLineName] = [{busLineId, coordinates}];
                    }
                });
            }

            updateBusData(groupedBusData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchStationsData(sD, eD, sDQuarter, eDQuarter, mapBounds, updateGroupedData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchStationsGeoData15min(latMax, lngMax, latMin, lngMin), // API-Aufruf für 15-Minuten-Daten
        ApiConnect.fetchStationsGeoDataJahreswerte(latMax, lngMax, latMin, lngMin), // API-Aufruf für Jahreswerte-Daten
        ApiConnect.fetchStationsGeoDataTimeRange(sD, eD, sDQuarter, eDQuarter, latMax, lngMax, latMin, lngMin)
    ])
        .then(([data15min, dataJahreswerte, dataRange]) => {
            const newData = {};

            if (data15min !== null && dataJahreswerte !== null && dataRange !== null) {
                // Daten zusammenführen (gruppieren) und in den Zustand speichern
                data15min.forEach(item => {
                    const {station_id, ...rest} = item;
                    newData[station_id] = {...(newData[station_id] || {}), ...rest};
                });

                dataJahreswerte.forEach(item => {
                    const {station_id, ...rest} = item;
                    newData[station_id] = {...(newData[station_id] || {}), ...rest};
                });

                dataRange.forEach(item => {
                    const {
                        station_id,
                        sum_countto,
                        sum_countfrom,
                        max_bothdirections,
                        sum_bothdirections,
                        ...rest
                    } = item;
                    newData[station_id] = {
                        ...(newData[station_id] || {}),
                        sum_countto_range: sum_countto,
                        sum_countfrom_range: sum_countfrom,
                        max_bothdirections_range: max_bothdirections,
                        sum_bothdirections_range: sum_bothdirections,
                        ...rest
                    };
                });
            }

            updateGroupedData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchCarStationsData(sD, eD, mapBounds, updateMapBounds, updateCarStations, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchCarStations(sD, eD, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const carStationsData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {
                        station_id,
                        sum_traffic_volume,
                        max_traffic_volume,
                        day_avg_traffic_volume,
                        hour_avg_traffic_volume,
                        avg_speed,
                        max_speed,
                        ...rest
                    } = item;
                    carStationsData[station_id] = {
                        ...(carStationsData[station_id] || {}),
                        sum_traffic_volume: sum_traffic_volume,
                        max_traffic_volume: max_traffic_volume,
                        day_avg_traffic_volume: day_avg_traffic_volume,
                        hour_avg_traffic_volume: hour_avg_traffic_volume,
                        avg_speed: avg_speed,
                        max_speed: max_speed,
                        ...rest,
                    };
                });
            }

            updateCarStations(carStationsData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchAccidents(sD, eD, sDQuarter, eDQuarter, mapBounds, updateGroupedAccData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchAccidentsGeoData(sD, eD, sDQuarter, eDQuarter, latMax, lngMax, latMin, lngMin)
        .then((dataAccidents) => {
            const newData = {};

            if (dataAccidents !== null) {
                let primaryKey = 1;

                dataAccidents.forEach(item => {
                    const datetime = new Date(item.datetime);
                    const year = datetime.getFullYear();
                    const month = datetime.getMonth() + 1;
                    const day = datetime.getDate();
                    const hours = datetime.getHours();
                    const minutes = datetime.getMinutes();

                    const formattedDateTime = `${datetime.getDate()}-${datetime.getMonth() + 1}-${datetime.getFullYear()} ${datetime.getHours()}:${datetime.getMinutes()}`;

                    if (!newData[year]) {
                        newData[year] = {};
                    }
                    if (!newData[year][month]) {
                        newData[year][month] = {};
                    }

                    newData[year][month][primaryKey] = {...item, formattedDateTime, year, month, day, hours, minutes};

                    primaryKey++;
                });
            }

            console.log(newData)
            updateGroupedAccData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchReportPlatformData(startDate, endDate, mapBounds, updateReportedImg, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchImageGeoDataForDashboard(startDate, endDate, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {annotation_id, ...rest} = item;
                    newData[annotation_id] = {...(newData[annotation_id] || {}), ...rest};
                });
            }

            console.log(newData)
            updateReportedImg(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}


export function fetchConcerns(sD, eD, mapBounds, updateGroupedConcernsData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchConcernsGeoData(sD, eD, latMax, lngMax, latMin, lngMin)
        .then((dataConcerns) => {
            const newData = {};

            if (dataConcerns !== null) {
                let primaryKey = 1;

                dataConcerns.forEach(item => {
                    const datetime = new Date(item.datetime);
                    const year = datetime.getFullYear();
                    const month = datetime.getMonth() + 1;
                    const day = datetime.getDate();

                    const formattedDateTime = `${datetime.getDate()}-${datetime.getMonth() + 1}-${datetime.getFullYear()}`;

                    if (!newData[year]) {
                        newData[year] = {};
                    }
                    if (!newData[year][month]) {
                        newData[year][month] = {};
                    }

                    newData[year][month][primaryKey] = {...item, formattedDateTime, year, month, day};

                    primaryKey++;
                });
            }

            updateGroupedConcernsData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchBusStopsData(mapBounds, updateBusStops, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchBusStops(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {bus_stop_id, ...rest} = item;
                    newData[bus_stop_id] = {...(newData[bus_stop_id] || {}), ...rest};
                });
            }

            updateBusStops(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

//segment count----------------------------------------------------------------------------------------------------

export function fetchAccidentCount(updateMergedRoadData, updateLoadingSpin, sD, eD) {
    Promise.all
        ([
            ApiConnect.fetchRoaddata(),
            ApiConnect.fetchAccidentsCountStreet("oldenburg", sD, eD)
        ])
        .then(([roadData, accidentData]) => {
            // Normalize accident road names and accumulate counts
            const accidentCountsByName = {};
            accidentData.forEach(accident => {
                if (accident.road_name) {
                    const normalizedAccidentName = accident.road_name.normalize('NFC');
                    accidentCountsByName[normalizedAccidentName] = (accidentCountsByName[normalizedAccidentName] || 0) + accident.count_;
                }
            });

            // Update road segments with accident counts
            const newData = roadData.map(road => {
                if (!road.road_name) {
                    return {
                        ...road,
                        accidentcount: 0
                    };
                }
                const normalizedRoadName = road.road_name.normalize('NFC');
                const accidentCount = accidentCountsByName[normalizedRoadName] || 0;
                return {
                    ...road,
                    accidentcount: accidentCount
                };
            });
            updateMergedRoadData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}


export function fetchElevation(updateInfrastructureData, updateLoadingSpin) {
    Promise.all
        ([
            ApiConnect.fetchRoaddata()
        ])
        .then(([roadData]) => {
            const newData = roadData.map(road => {
                return {
                    ...road
                };
            });
            console.log(newData);
            updateInfrastructureData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchAccidentSegmentCount(updateAccidentRoadData, mapBounds, updateLoadingSpin, sD, eD) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchRoadSegmentsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchAccidentsGeoDataBounded(sD, eD, "00:00:00", "23:59:00", latMax, lngMax, latMin, lngMin)
    ])
        .then(([segments, accidents]) => {
            const segmentAccidentCount = new Map();
            const segmentAccidentTypeCount = new Map();
            const bufferDistance = 0.000100; // Buffer distance in degrees

            accidents.forEach(accident => {
                const latitude = parseFloat(String(accident.euska_latitude).trim());
                const longitude = parseFloat(String(accident.euska_longitude).trim());

                segments.forEach(segment => {
                    const coordinates = segment.geometry.coordinates;

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [x1, y1] = coordinates[i];
                        const [x2, y2] = coordinates[i + 1];

                        const minX = Math.min(x1, x2) - bufferDistance;
                        const maxX = Math.max(x1, x2) + bufferDistance;
                        const minY = Math.min(y1, y2) - bufferDistance;
                        const maxY = Math.max(y1, y2) + bufferDistance;

                        if (longitude >= minX && longitude <= maxX && latitude >= minY && latitude <= maxY) {
                            const currentCount = segmentAccidentCount.get(segment.segment_id) || 0;
                            segmentAccidentCount.set(segment.segment_id, currentCount + 1);

                            const accType = accident.euska_accident_type_long;
                            if (accType) {
                                const accTypeCounts = segmentAccidentTypeCount.get(segment.segment_id) || {};
                                accTypeCounts[accType] = (accTypeCounts[accType] || 0) + 1;
                                segmentAccidentTypeCount.set(segment.segment_id, accTypeCounts);
                            }
                        }
                    }
                });
            });

            const mergedRoadData = segments.map(segment => ({
                ...segment,
                accident_count: segmentAccidentCount.get(segment.segment_id) || 0,
                accident_type_counts: segmentAccidentTypeCount.get(segment.segment_id) || {}
            }));

            console.log(mergedRoadData);
            updateAccidentRoadData(mergedRoadData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchAccidentRateSegmentCount2(updateAccidentRateData, mapBounds, updateLoadingSpin, sD, eD, town) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchRoadswithAccidentsData(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchBikeTrafficAllStreets(town, [sD, eD]),
    ])
        .then(([accidents, biketraffic]) => {

            // Umwandeln der biketraffic-Daten in ein Objekt zur einfachen Suche
            const bikeData = biketraffic.reduce((acc, station) => {
                acc[station.road_name] = station;
                return acc;
            }, {});

            // Umwandeln der accidents-Daten in ein Objekt zur einfachen Suche
            const roadData = accidents.reduce((acc, road) => {
                acc[road.road_name] = road;
                return acc;
            }, {});

            let count = 0;

            const mergedData = accidents
                .filter(accItem => bikeData[accItem.road_name])  // Nur Straßen berücksichtigen, die in beiden Fetches vorkommen
                .map(accItem => {
                    const bikeStation = bikeData[accItem.road_name];
                    const roadInfo = roadData[accItem.road_name];


                    if (!roadInfo) {
                        return null; // Überspringen des Eintrags, wenn roadInfo undefined ist
                    }

                    let totalTraffic;
                    let numDays = 1000;

                    if (bikeStation.sum_countto === 0 && bikeStation.sum_countfrom === 0) {
                        totalTraffic = bikeStation.sum_bothdirections / numDays;
                    } else {
                        totalTraffic = (bikeStation.sum_countto + (bikeStation.sum_countfrom === 0 ? bikeStation.sum_countto : bikeStation.sum_countfrom)) / numDays;
                    }



                    const roadLength = roadInfo.length; // Annahme, dass roadInfo eine Länge-Eigenschaft hat
                    const accidentsCount = accItem.accidents * 1000000;

                    totalTraffic = (totalTraffic / numDays) * roadLength * 365;

                    // Wenn totalTraffic 0 ist, überspringen wir den Eintrag
                    if (totalTraffic === 0) {
                        return null;
                    }
                    count++;

                    return {
                        count: count,
                        road_name: accItem.road_name,
                        unfallrate: (accidentsCount / totalTraffic),
                        geometry: accItem.geometry,
                    };
                })
                .filter(item => item !== null); // Filtern der übersprungenen Einträge


            const resultRoadData = mergedData.map(segment => ({
                count: segment.count,
                road_name: segment.road_name,
                unfallrate: segment.unfallrate,
                geometry: segment.geometry,
            }));

            console.log(resultRoadData);
            updateAccidentRateData(resultRoadData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
            updateLoadingSpin(false);
        });
}

export function fetchAccidentRateSegmentCount(updateAccidentRateData, mapBounds, updateLoadingSpin, sD, eD, town) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchRoadsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchAccidentsGeoDataBounded(sD, eD, "00:00:00", "23:59:00", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchBikeTrafficAllStreets(town, [sD, eD]),
    ])
        .then(([segments, accidents, biketraffic]) => {
            const startDate = new Date(sD);
            const endDate = new Date(eD);
            const numDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

            const bufferDistance = 0.000500;
            const segmentBikeMap = new Map();

            biketraffic.forEach(station => {
                const latitude = parseFloat(station.latitude);
                const longitude = parseFloat(station.longitude);
                const bikeTrafficId = station.road_name;

                segments.forEach(segment => {
                    const coordinates = segment.geometry.coordinates;

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [x1, y1] = coordinates[i];
                        const [x2, y2] = coordinates[i + 1];

                        const minX = Math.min(x1, x2);
                        const maxX = Math.max(x1, x2);
                        const minY = Math.min(y1, y2);
                        const maxY = Math.max(y1, y2);

                        if (longitude >= minX && longitude <= maxX && latitude >= minY && latitude <= maxY) {
                            if (!segmentBikeMap.has(bikeTrafficId)) {
                                segmentBikeMap.set(bikeTrafficId, {
                                    stations: [],
                                    totalLength: 0,
                                    geometry: segment.geometry.coordinates,
                                    sum_countto: 0,
                                    sum_countfrom: 0,
                                    accidentsCount: 0
                                });
                            }
                            const entry = segmentBikeMap.get(bikeTrafficId);
                            entry.stations.push(station);
                            entry.totalLength += segment.length;
                            entry.sum_countto += station.sum_countto;
                            entry.sum_countfrom += station.sum_countfrom;
                        }
                    }
                });
            });

            segmentBikeMap.forEach((entry, bikeTrafficId) => {
                const { geometry } = entry;

                accidents.forEach(accident => {
                    const accidentLatitude = parseFloat(String(accident.euska_latitude).trim());
                    const accidentLongitude = parseFloat(String(accident.euska_longitude).trim());

                    for (let i = 0; i < geometry.length - 1; i++) {
                        const [x1, y1] = geometry[i];
                        const [x2, y2] = geometry[i + 1];

                        const minX = Math.min(x1, x2) - bufferDistance;
                        const maxX = Math.max(x1, x2) + bufferDistance;
                        const minY = Math.min(y1, y2) - bufferDistance;
                        const maxY = Math.max(y1, y2) + bufferDistance;

                        if (accidentLongitude >= minX && accidentLongitude <= maxX &&
                            accidentLatitude >= minY && accidentLatitude <= maxY) {
                            entry.accidentsCount++;
                        }
                    }
                });

                const { accidentsCount, sum_countto, sum_countfrom, totalLength } = entry;
                const totalTraffic = sum_countto + sum_countfrom;
                const adjustedTraffic = (totalTraffic / numDays) * totalLength;
                entry.unfallrate = adjustedTraffic > 0 ? (accidentsCount * 100000 / adjustedTraffic) : 0;
            });

            const maxUnfallRate = Math.max(...Array.from(segmentBikeMap.values()).map(entry => entry.unfallrate));

            const finalResults = Array.from(segmentBikeMap.entries()).map(([bikeTrafficId, entry]) => ({
                stationname: bikeTrafficId,
                accidentsCount: entry.accidentsCount,
                length: entry.totalLength,
                unfallrate: entry.unfallrate,
                geometry: entry.geometry,
                max: maxUnfallRate
            }));
            console.log(finalResults);
            updateAccidentRateData(finalResults);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            updateLoadingSpin(false);
        });
}

export function fetchTrafficCount(updateTrafficRoadData, mapBounds, updateLoadingSpin, sD, eD) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);
    Promise.all([
        ApiConnect.fetchRoadSegmentsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchCarStations(sD, eD, latMax, lngMax, latMin, lngMin)
    ])
        .then(([roadData, trafficData]) => {
            const carStationsData = {};

            if (trafficData !== null) {
                trafficData.forEach(item => {
                    const {
                        station_id,
                        sum_traffic_volume,
                        max_traffic_volume,
                        day_avg_traffic_volume,
                        hour_avg_traffic_volume,
                        avg_speed,
                        max_speed,
                        ...rest
                    } = item;
                    carStationsData[station_id] = {
                        ...(carStationsData[station_id] || {}),
                        sum_traffic_volume,
                        max_traffic_volume,
                        day_avg_traffic_volume,
                        hour_avg_traffic_volume,
                        avg_speed,
                        max_speed,
                        ...rest,
                    };
                });
            }

            const count = new Map();
            const segmentSumTrafficData = new Map();
            const segmentDailyTrafficData = new Map();
            const segmentHourlyTrafficData = new Map();
            const segmentSpeedData = new Map();

            const bufferDistance = 0.00001; // Buffer distance in degrees

            Object.values(carStationsData).forEach(carStation => {
                const { latitude, longitude, sum_traffic_volume, day_avg_traffic_volume, hour_avg_traffic_volume, avg_speed } = carStation;

                roadData.forEach(segment => {
                    const coordinates = segment.geometry.coordinates;

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [x1, y1] = coordinates[i];
                        const [x2, y2] = coordinates[i + 1];

                        const minX = Math.min(x1, x2) - bufferDistance;
                        const maxX = Math.max(x1, x2) + bufferDistance;
                        const minY = Math.min(y1, y2) - bufferDistance;
                        const maxY = Math.max(y1, y2) + bufferDistance;


                        if (longitude >= minX && longitude <= maxX && latitude >= minY && latitude <= maxY) {

                            const counting = count.get(segment.segment_id) || 0;
                            count.set(segment.segment_id, counting + 1);

                            const sum_Traffic_Count = segmentSumTrafficData.get(segment.segment_id) || 0;
                            segmentSumTrafficData.set(segment.segment_id, sum_Traffic_Count + sum_traffic_volume);

                            const daily_Traffic_Count = segmentDailyTrafficData.get(segment.segment_id) || 0;
                            segmentDailyTrafficData.set(segment.segment_id, daily_Traffic_Count + day_avg_traffic_volume);

                            const hour_avg_Traffic_Count = segmentHourlyTrafficData.get(segment.segment_id) || 0;
                            segmentHourlyTrafficData.set(segment.segment_id, hour_avg_Traffic_Count + hour_avg_traffic_volume);

                            const avg_speed_Count = segmentSpeedData.get(segment.segment_id) || 0;
                            segmentSpeedData.set(segment.segment_id, avg_speed_Count + avg_speed);
                        }

                    }
                });
            });

            const maxArray = roadData.map(segment => {
                const stations = count.get(segment.segment_id) || 1;
                const dailyTraffic = segmentDailyTrafficData.get(segment.segment_id) || 0;
                return dailyTraffic / stations;
            });

            const top15Values = maxArray.sort((a, b) => b - a).slice(0, 15);

            // Mittelwert der Top 15
            const averageTop15 = top15Values.reduce((sum, value) => sum + value, 0) / top15Values.length;

            console.log(`Average of top 15 values: ${averageTop15}`);


            const mergedTrafficData = roadData.map(segment => {
                const stations = count.get(segment.segment_id) || 1;

                return {
                    ...segment,
                    stations: count.get(segment.segment_id) || 1,
                    sum_traffic_count: segmentSumTrafficData.get(segment.segment_id) / stations || 0,
                    day_avg_traffic_count: segmentDailyTrafficData.get(segment.segment_id) / stations || 0,
                    hour_avg_traffic_count: segmentHourlyTrafficData.get(segment.segment_id) / stations || 0,
                    avg_speed_count: segmentSpeedData.get(segment.segment_id) / stations || 0,
                    max: averageTop15.toFixed(0)
                }
            });


            console.log(mergedTrafficData);
            updateTrafficRoadData(mergedTrafficData);
            updateLoadingSpin(false);
        })

        .catch(error => {
            console.error(error);
            updateLoadingSpin(false);
        });
}

export function fetchConcernsSegmentCount(updateConcernsRoadData, mapBounds, updateLoadingSpin, sD, eD) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchRoadSegmentsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchConcernsGeoData(sD, eD, latMax, lngMax, latMin, lngMin)
    ])
        .then(([segments, concerns]) => {
            const segmentConcernsCount = new Map();
            const segmentConcernTypeCount = new Map();
            const bufferDistance = 0.0001;

            concerns.forEach(concern => {
                const latitude = parseFloat(String(concern.latitude).trim());
                const longitude = parseFloat(String(concern.longitude).trim());

                segments.forEach(segment => {
                    const coordinates = segment.geometry.coordinates;

                    for (let i = 0; i < coordinates.length - 1; i++) {
                        const [x1, y1] = coordinates[i];
                        const [x2, y2] = coordinates[i + 1];

                        const minX = Math.min(x1, x2) - bufferDistance;
                        const maxX = Math.max(x1, x2) + bufferDistance;
                        const minY = Math.min(y1, y2) - bufferDistance;
                        const maxY = Math.max(y1, y2) + bufferDistance;

                        if (longitude >= minX && longitude <= maxX && latitude >= minY && latitude <= maxY) {
                            const currentCount = segmentConcernsCount.get(segment.segment_id) || 0;
                            segmentConcernsCount.set(segment.segment_id, currentCount + 1);

                            const concType = concern.topic_text;
                            if (concType) {
                                const concTypeCounts = segmentConcernTypeCount.get(segment.segment_id) || {};
                                concTypeCounts[concType] = (concTypeCounts[concType] || 0) + 1;
                                segmentConcernTypeCount.set(segment.segment_id, concTypeCounts);
                            }
                        }
                    }
                });
            });

            const mergedConcernData = segments.map(segment => ({
                ...segment,
                concern_count: segmentConcernsCount.get(segment.segment_id) || 0,
                concern_type_counts: segmentConcernTypeCount.get(segment.segment_id) || {}
            }));

            console.log(mergedConcernData);
            updateConcernsRoadData(mergedConcernData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}


export function fetchRoadDamageSegmentCount(updateRoadDamageData, mapBounds, updateLoadingSpin, sD, eD) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    Promise.all([
        ApiConnect.fetchRoadSegmentsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadDamageMessages(latMax, lngMax, latMin, lngMin)
    ])
        .then(([segments, roadDamageData]) => {
            const segmentRoadDamageCount = new Map();
            const bufferDistance = 0.0001; // Buffer distance in degrees

            segments.forEach(segment => {
                let minX, maxX, minY, maxY;
                const coordinates = segment.geometry.coordinates;
                console.log(coordinates)
                for (let i = 0; i < coordinates.length - 1; i++) {
                    const [x1, y1] = coordinates[i];
                    const [x2, y2] = coordinates[i + 1];

                    minX = Math.min(x1, x2) - bufferDistance;
                    maxX = Math.max(x1, x2) + bufferDistance;
                    minY = Math.min(y1, y2) - bufferDistance;
                    maxY = Math.max(y1, y2) + bufferDistance;
                }

                roadDamageData.forEach(rdd => {
                    let time = rdd.upload_date;
                    const latitude = rdd.latitude;
                    const longitude = rdd.longitude;
                    if (time >= sD && time <= eD) {
                        if (longitude >= minX && longitude <= maxX && latitude >= minY && latitude <= maxY) {
                            console.log("in");
                            const currentCount = segmentRoadDamageCount.get(segment.segment_id) || 0;
                            segmentRoadDamageCount.set(segment.segment_id, currentCount + 1);
                        }
                    }
                });
            }
            );

            const mergedRoadDamageData = segments.map(segment => ({
                ...segment,
                damage_count: segmentRoadDamageCount.get(segment.segment_id) || 0
            }));

            console.log(mergedRoadDamageData);
            updateRoadDamageData(mergedRoadDamageData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}



//node count----------------------------------------------------------------------------------------------------

export function fetchAccidentNodeCount(updateAccidentNodeData, mapBounds, updateLoadingSpin, sD, eD) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);
    Promise.all([
        ApiConnect.fetchOnlyNode(),
        ApiConnect.fetchAccidentsGeoDataBounded(sD, eD, "00:00:00", "23:59:00", latMax, lngMax, latMin, lngMin)
    ])
        .then(([nodes, accidents]) => {
            nodes = nodes.map((node, index) => ({
                ...node,
                node_id: index + 1
            }));

            const nodeAccidentCount = new Map();
            const nodeAccidentTypeCount = new Map();

            accidents.forEach(accident => {
                const latitude = parseFloat(String(accident.euska_latitude).trim());
                const longitude = parseFloat(String(accident.euska_longitude).trim());

                const accidentPoint = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [longitude, latitude]
                    }
                };

                nodes.forEach(node => {
                    const nodePolygon = {
                        type: "Feature",
                        geometry: node.geometry
                    };

                    if (booleanPointInPolygon(accidentPoint, nodePolygon)) {
                        const currentCount = nodeAccidentCount.get(node.node_id) || 0;
                        nodeAccidentCount.set(node.node_id, currentCount + 1);

                        const accType = accident.euska_accident_type_long;
                        if (accType) {
                            const accTypeCounts = nodeAccidentTypeCount.get(node.node_id) || {};
                            accTypeCounts[accType] = (accTypeCounts[accType] || 0) + 1;
                            nodeAccidentTypeCount.set(node.node_id, accTypeCounts);
                        }
                    }
                });
            });

            const mergedNodeData = nodes.map(node => ({
                ...node,
                accident_count: nodeAccidentCount.get(node.node_id) || 0,
                accident_type_counts: nodeAccidentTypeCount.get(node.node_id) || {},
                geometry: {
                    ...node.geometry,
                    coordinates: node.geometry.coordinates[0].map(value => [
                        value[1],
                        value[0]
                    ])
                }
            }));

            console.log(mergedNodeData);
            updateAccidentNodeData(mergedNodeData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchRoadGeometry(mapBounds, updateRoadGeometry, updateLoadingSpin) {
    const { latMax, lngMax, latMin, lngMin } = getMapBounds(mapBounds);

    ApiConnect.fetchRoadFeatures(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            console.log(data);

            if (data !== null) {
                data.forEach(item => {
                    const { geometry_id, slope, path_type, path, surface_type, width, geometry, ...rest } = item;

                    if (slope !== null || path_type !== null || path !== null || surface_type !== null ||
                        width !== null) {

                        let coordinates = geometry.coordinates;
                        coordinates.forEach(function (value, i) {
                            coordinates[i] = [value[1], value[0]];
                        });
                        geometry.coordinates = coordinates;

                        newData[geometry_id] = { ...(newData[geometry_id] || {}), slope, path_type, path, surface_type,
                            width, geometry, ...rest }
                    }
                });
            }

            updateRoadGeometry(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}
