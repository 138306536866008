import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { Container, Button } from "react-bootstrap";
import AddressSearchBar from "../report/AddressSearchBar";
import FastApiSegment from "./FastApiSegment";
import OsmSegment from "./OsmSegment";
import ImportSegment from "./ImportSegment";
import FilterComponent from "./FilterComponent";
import SegmenteEditComponent from "./SegmenteEditComponent";
import ExportComponent from "./ExportComponent";
import NavComponent from "./NavComponent";
import TabellenComponent from "./TabellenComponent";
import "../../styles/Infrastructure.css";

const InfrastructureMain = () => {
  const [segment, setSegments] = useState([]);
  const [segment2, setSegments2] = useState([]);
  const [segment3, setSegments3] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [mapView, setMapView] = useState('standard');
  const [center, setCenter] = useState([53.1435, 8.2146]);
  const mapRef = useRef(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const [fuhrungsformValue, setFuhrungsformValue] = useState("");
  const [oberflaecheValue, setOberflaecheValue] = useState("");
  const [tempolimitValue, setTempolimitValue] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [entries, setEntries] = useState([]);
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState(new Set());
  const [dataSource, setDataSource] = useState('overpass');
  const [key, setKey] = useState(0);
  const [navShow, setNavShow] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    highway: '',
    bicycle: '',
    foot: '',
    surface: '',
    lit: '',
    traffic_sign: '',
    maxspeed: '',
    fuhrungsform: '',
    oberflaeche: '',
    width:'',
    rcn:'',
    bicycle_road:'',
  });

  const handleNavShow = () => {
    setNavShow(!navShow); 
  };

  const updateMapBounds = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      console.log("Aktuelle Karten-Grenzen:", bounds);
    }
  };

  const MapEvents = () => {
    useMapEvents({
      moveend: updateMapBounds,
      zoomend: updateMapBounds,
    });
    return null;
  };

  const handleMapReady = (event) => {
    const mapInstance = event.target;
    mapRef.current = mapInstance;
    updateMapBounds();
  };

  const handleAddressSelected = (address) => {
    const { lat, lon } = address;
    setCenter([lat, lon]);
    setKey((prevKey) => prevKey + 1);
  };

  const handleMapTypeChange = (newMapView) => {
    const newZoom = newMapView === 'standard' ? 13 : 10;
    setMapView(newMapView);
    setZoom(newZoom);
  };

  const handleLoadSegments = (source) => {
    setDataSource(source);
      };

  return (
    <Container fluid className="map-container">
      <MapContainer
        key={key}
        center={center}
        zoom={zoom}
        whenReady={handleMapReady}
        style={{ height: "100%", width: "100%" }}
      >
        {mapView === 'standard' && (
          <TileLayer
            attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors"
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            subdomains={['a', 'b', 'c', 'd']}
          />
        )}
        {mapView === 'satellite' && (
          <TileLayer
            attribution="&copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        )}
        <div className="flexMapTest row">
          <AddressSearchBar
            className="address-search address-search-wrapper col"
            onAddressSelected={handleAddressSelected}
          />
          <div className="map-type-toggle dissapear col">
            <button
              className={mapView === 'standard' ? 'active' : ''}
              onClick={() => handleMapTypeChange('standard')}
            >
              Standardmap
            </button>
            <button
              className={mapView === 'satellite' ? 'active' : ''}
              onClick={() => handleMapTypeChange('satellite')}
            >
              Satellitenmap
            </button>
          </div>
        </div>

        <div className="testbutton-container">
          <button
            className="test-button" onClick={handleNavShow}>
            Optionen
          </button>
          </div>

        {dataSource === 'fastapi' && (
          <FastApiSegment
            segment={segment}
            mapBounds={mapBounds}
            setSegments={setSegments}
            selectedSegments={selectedSegments}
            multiSelectMode={multiSelectMode}
            setSelectedSegmentId={setSelectedSegmentId}
            setModalOpen={setModalOpen}
            setSelectedSegments={setSelectedSegments}
          />
        )}

        {dataSource === 'overpass' && (
          <OsmSegment
            segment={segment2}
            entries={entries}
            filters={filters}
            mapBounds={mapBounds}
            setSegments={setSegments2}
            selectedSegments={selectedSegments}
            multiSelectMode={multiSelectMode}
            setSelectedSegmentId={setSelectedSegmentId}
            setModalOpen={setModalOpen}
            setSelectedSegments={setSelectedSegments}
          />
        )}

        {dataSource === 'imported' && (
          <ImportSegment
            segment={segment3}
            mapBounds={mapBounds}
            setSegments={setSegments3}
            selectedSegments={selectedSegments}
            multiSelectMode={multiSelectMode}
            setSelectedSegmentId={setSelectedSegmentId}
            importModalOpen={importModalOpen} 
            setImportModalOpen={setImportModalOpen}
            setSelectedSegments={setSelectedSegments}
          />
        )}

        <MapEvents />
      </MapContainer>

      <NavComponent
        handleLoadSegments={handleLoadSegments}
        setFilterModalOpen={setFilterModalOpen}
        toggleMultiSelectMode={() => setMultiSelectMode(!multiSelectMode)}
        toggleOffcanvas={() => setShowOffcanvas(!showOffcanvas)}
        setExportModalOpen={setExportModalOpen}
       setImportModalOpen={setImportModalOpen}
        multiSelectMode={multiSelectMode}
        selectedSegments={selectedSegments}
        navShow={navShow} 
        handleNavShow={handleNavShow} 
        setModalOpen={setModalOpen}
      />

      <FilterComponent
      filters={filters}
      setFilters={setFilters}
        filterModalOpen={filterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        entries={entries}
        setEntries={setEntries}
      />

      <SegmenteEditComponent
        modalOpen={modalOpen}
        handleModalClose={() => setModalOpen(false)}
        handleFormSubmit={() => {}}
        selectedSegmentId={selectedSegmentId}
        fuhrungsformValue={fuhrungsformValue}
        setFuhrungsformValue={setFuhrungsformValue}
        oberflaecheValue={oberflaecheValue}
        setOberflaecheValue={setOberflaecheValue}
        tempolimitValue={tempolimitValue}
        setTempolimitValue={setTempolimitValue}
        segment={segment2}
        entries={entries}
        setEntries={setEntries}
        multiSelectMode={multiSelectMode}
        selectedSegments={selectedSegments}
      />

      <ExportComponent
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
        entries={entries}
        segment={segment2}
      />

      <TabellenComponent
        showOffcanvas={showOffcanvas}
        toggleOffcanvas={() => setShowOffcanvas(!showOffcanvas)}
        entries={entries}
        setEntries={setEntries}
        setSelectedSegmentId={setSelectedSegmentId}
        setSegmentName={setSegmentName}
        setFuhrungsformValue={setFuhrungsformValue}
        setOberflaecheValue={setOberflaecheValue}
        setTempolimitValue={setTempolimitValue}
        setModalOpen={setModalOpen}
        setSelectedSegments={setSelectedSegments}
      />
    </Container>
  );
};

export default InfrastructureMain;