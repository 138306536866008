import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {bikeIcon} from "../../functions/Icons";
import dayjs from "dayjs";
import {fetchStationsData} from "../../functions/MapFunctions";

function BicycleCountingLayer({bikeChecked, groupedData, sD, eD, sDQuarter, eDQuarter, mapBounds, loadingSpin,
                                  updateGroupedData, updateLoadingSpin}) {

    const useStationsData = useCallback(() => {
        fetchStationsData(sD, eD, sDQuarter, eDQuarter, mapBounds, updateGroupedData, updateLoadingSpin);
    }, [sD, eD, sDQuarter, eDQuarter, mapBounds]);

    useEffect(() => {
        if (bikeChecked) {
            useStationsData();
        }
    }, [sD, eD, sDQuarter, eDQuarter, mapBounds, bikeChecked]);

    return (
        <LayersControl.Overlay name="7: Rad Zählstellen" checked={bikeChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(groupedData).map(stationId => {
                    const station = groupedData[stationId];

                    const content = [
                        {text: "Zeitraum: ", value: dayjs(sD).format("DD.MM.YYYY") + " - " + dayjs(eD).format("DD.MM.YYYY")},
                        {text: "ID: ", value: stationId},
                        {text: "Straße: ", value: station.road_name},
                        {text: "Stoßtag: ", value: station.max_dow_peak_bothdirections_time},
                        {text: "", value: ""},
                        {text: "Beide Richtungen (gerundet)", value: ""},
                        {text: "Radfahrer im Zeitraum: ", value: station.sum_bothdirections_range.toFixed(0)},
                        {text: "Radfahrer pro Woche: ", value: station.avg_sum_bothdirections && ((station.avg_sum_bothdirections) * 7).toFixed(0)},
                        {text: "Radfahrer pro Tag: ", value: station.avg_sum_bothdirections && station.avg_sum_bothdirections.toFixed(0)},
                        {text: "Radfahrer pro h: ", value: station.hour_avg_bothdirections.toFixed(0)},
                        {text: "Radfahrer pro 15min: ", value: ((station.hour_avg_bothdirections) / 4).toFixed(0)},
                        {text: "", value: ""},
                        {text: "Zur Stadt / aus der Stadt (gerundet):", value: ""},
                        {text: "Anzahl Radfahrer im Zeitraum: ", value: station.sum_countto_range.toFixed(0) + " / " + station.sum_countfrom_range.toFixed(0)},
                        {text: "Durchschnittswerte:", value: ""},
                        {text: "Radfahrer pro Woche: ", value: ((station.day_avg_countto) * 7).toFixed(0) + " / " + ((station.day_avg_countfrom) * 7).toFixed(0)},
                        {text: "Radfahrer pro Tag: ", value: station.day_avg_countto.toFixed(0) + " / " + station.day_avg_countfrom.toFixed(0)},
                        {text: "Radfahrer pro h: ", value: station.hour_avg_countto.toFixed(0)+ " / " + station.hour_avg_countfrom.toFixed(0)},
                        {text: "Radfahrer pro 15min: ", value: ((station.hour_avg_countto) / 4).toFixed(0) + " / " + ((station.hour_avg_countfrom) / 4).toFixed(0)},
                    ];

                    return (
                        <Marker position={[station.latitude, station.longitude]} icon={bikeIcon}>
                            <Popup>
                                {content.map(value => ( <> <strong> {value.text} </strong> {value.value} <br/> </> ))}
                            </Popup>
                        </Marker>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default BicycleCountingLayer;