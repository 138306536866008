import React, { useState } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import { Modal, Button } from "react-bootstrap";
import proj4 from 'proj4';

proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");

const ImportSegment = ({
  segment,
  mapBounds,
  setSegments,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments,
  importModalOpen, 
  setImportModalOpen
}) => {
  const [geojsonData, setGeojsonData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        try {
          const parsedData = JSON.parse(content);
          if (parsedData.type === "FeatureCollection" && Array.isArray(parsedData.features)) {
            setGeojsonData(parsedData.features);
          } else {
            console.error("Invalid GeoJSON format");
          }
        } catch (error) {
          console.error("Error parsing GeoJSON:", error);
        }
      };
      reader.readAsText(file);
    } else {
      console.error("No file selected.");
    }
  };

  const inputProjection = 'EPSG:25832';
  const outputProjection = 'EPSG:4326';

  const convertCoordinates = (coordinates) => {
    return coordinates.map(coord => {
      if (typeof coord[0] !== 'number' || typeof coord[1] !== 'number') {
        console.error("Invalid coordinate:", coord);
        return null;
      }
      const [lng, lat] = proj4(inputProjection, outputProjection, [coord[0], coord[1]]);
      return [lat, lng];
    });
  };

  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };

  return (
    <>
      {/* Import Modal */}
      <Modal
        show={importModalOpen}
        onHide={() => setImportModalOpen(false)}
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Optionen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>GeoJson importieren</p>
          <input
            type="file"
            accept=".geojson"
            onChange={handleFileUpload} 
          />
          <Button className="w-100" onClick={() => setImportModalOpen(false)}>
            Import abschließen
          </Button>
        </Modal.Body>
      </Modal>

      {geojsonData.map((feature) => {
        if (feature.geometry) {
          if (feature.geometry.type === "MultiLineString") {
            return feature.geometry.coordinates.map((line, lineIndex) => (
              <Polyline
                key={`geojson-${feature.osm_id}-${lineIndex}`}
                positions={convertCoordinates(line)}
                weight={3}
                fill={false}
                opacity={1}
                color="black"
                eventHandlers={{
                  click: () => handlePolylineClick(feature.osm_id),
                }}
              >
                <Tooltip>
                  {feature.properties.display_name || "No Name"}
                </Tooltip>
              </Polyline>
            ));
          }

          if (feature.geometry.type === "LineString") {
            return (
              <Polyline
                key={`geojson-${feature.osm_id}`}
                positions={convertCoordinates(feature.geometry.coordinates)}
                weight={3}
                fill={false}
                opacity={1}
                color="black"
                eventHandlers={{
                  click: () => handlePolylineClick(feature.osm_id),
                }}
              >
                <Tooltip>
                  {feature.properties.display_name || "No Name"}
                </Tooltip>
              </Polyline>
            );
          }
        }
        return null;
      })}
    </>
  );
};

export default ImportSegment;