import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from 'xlsx';

const ExportComponent = ({ exportModalOpen, setExportModalOpen, entries, segment }) => {
  const exportNewEntries = () => {
    if (entries.length === 0) {
      console.warn("Keine neuen Einträge.");
      return;
    }
    const formattedEntries = entries.map(entry => ({
      ...entry,
      geometry: JSON.stringify(entry.geometry)
    }));
    console.log("Exportierte neue Einträge:", formattedEntries);
    const worksheet = XLSX.utils.json_to_sheet(formattedEntries);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Neue Einträge");
    XLSX.writeFile(workbook, "neue_eintraege.xlsx");
  };

  const exportAllSegments = () => {
    if (segment.length === 0) {
      console.warn("Keine Segmente.");
      return;
    }
    const formattedSegments = segment.map(seg => ({
      ...seg,
      geometry: JSON.stringify(seg.geometry)
    }));
    console.log("Exportierte Segmente:", formattedSegments);
    const worksheet = XLSX.utils.json_to_sheet(formattedSegments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alle Segmente");
    XLSX.writeFile(workbook, "alle_segmente.xlsx");
  };

  const exportFilteredSegments = () => {
    const filteredSegments = segment.filter(matchesFilter);
    if (filteredSegments.length === 0) {
      console.warn("Keine gefilterten Segmente.");
      return;
    }
    const formattedFilteredSegments = filteredSegments.map(seg => ({
      ...seg,
      geometry: JSON.stringify(seg.geometry)
    }));
    console.log("Exportierte gefilterte Segmente:", formattedFilteredSegments);
    const worksheet = XLSX.utils.json_to_sheet(formattedFilteredSegments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Gefilterte Segmente");
    XLSX.writeFile(workbook, "gefilterte_segmente.xlsx");
  };

  return (
    <Modal
      show={exportModalOpen}
      onHide={() => setExportModalOpen(false)}
      centered
      className="modal-export"
    >
      <Modal.Header closeButton>
        <Modal.Title>Export Optionen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button variant="primary" className="w-100 mb-2" onClick={exportNewEntries}>
          Neue Einträge exportieren
        </Button>
        <Button variant="primary" className="w-100 mb-2" onClick={exportAllSegments}>
          Alle Segmente exportieren
        </Button>
        <Button variant="primary" className="w-100" onClick={exportFilteredSegments}>
          Gefilterte Segmente exportieren
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ExportComponent;