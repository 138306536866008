import ConnectionError from "./ConnectionError";

const domain = "https://api.radweg-radar.de/api/v1/"
const headers = { 'Content-Type': 'application/json' };
const method = 'POST'

/* Possible Values: 'injured', 'snowdepth', 'sealevelpressure', 'quarter', 'month', 'euska_accident_type_long',
   'euska_participant', 'mint', 'perpetrator_german', 'dow', 'heatindex', 'number_participants', 'slightly_injured',
   'euska_lighting', 'day_datetime', 'dew', 'cognos_alcohol', 'perpetrator', 'solarenergy', 'maxt', 'cloudcover',
   'precip', 'year', 'month_datetime', 'year_datetime', 'euska_participant_german', 'euska_accident_color_simplified',
   'quarter_datetime', 'snow', 'euska_accident_type', 'euska_road_condition', 'euska_latitude', 'humidity',
   'cognos_accident_type', 'visbibility', 'wspd', 'city', 'serious_injured', 'precipcover', 'windchill', 'road_name',
   'euska_longitude', 'dow_datetime', 'wgust', 'datetime', 'euska_accident_type_simplified', 'euska_road_segment',
   'killed', 'cognos_monetary_damage', 'day', 'hour_datetime', 'cognos_drugs', 'euska_accident_color', 'hour',
   'solarradiation', 'temp', 'wdir' */

async function fetchData(endpoint, body=null) {
    try {
        if (body === null) {
            const response = await fetch(endpoint);
            const data = await response.json();
            return JSON.parse(data);
        } else {
            const response = await fetch(endpoint, {
                method: method,
                headers: headers,
                body: body,
                credentials: "omit"
            });
            const data = await response.json();

            return JSON.parse(data);
        }
    } catch (error) {
        throw new ConnectionError('Verbindung zur Datenbank fehlerhaft. Versuchen Sie es erneut.');
    }
}

export default class ApiConnection {

    /*---------------------RoadScore-----------------------------------------------------*/
    /*---------------------Map-------------*/

    async fetchRoadScoreData() {
        const body = '{"columns": ["accidents_per_m","concerns_per_m","damages_per_m","quality_total",' +
            '"v_score_v2","anomaly","time_loss","geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }

    async fetchOnlyRoadScore() {
        const body = '{"columns": ["road_name","full_percentile_score","full_percentile_accidents_per_m",' +
            '"full_percentile_concerns_per_m","full_percentile_v_score_v2","full_percentile_anomaly",' +
            '"full_percentile_time_loss","full_percentile_daily_traffic","geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchOnlyRoadScoreCol(column, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["road_name","' + column + '","geometry", "latitude","longitude"], ' +
            '"conditions": [{"column":"latitude", "operator": "<=", "value": ' + latMax + '}, {"column":"latitude", ' +
            '"operator": ">=", "value": ' + latMin + '}, {"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, ' +
            '{"column":"longitude", "operator": ">=", "value": ' + lngMin + '}], "group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchRoadScoreStreet(road, city) {
        const fullBody = '{"columns": ["road_name","full_percentile_score","full_percentile_accidents_per_m",' +
            '"full_percentile_concerns_per_m","full_percentile_damages_per_m","full_percentile_quality_total",' +
            '"full_percentile_v_score_v2","full_percentile_anomaly","full_percentile_time_loss",' +
            '"full_percentile_daily_traffic"], "conditions": [{"column":"road_name","value":"' + road + '"},' +
            '{"column":"city","value":"' + city + '"}],"group_by": []}';
        const reducedBody = '{"columns": ["road_name","full_percentile_score","full_percentile_accidents_per_m",' +
            '"full_percentile_concerns_per_m","full_percentile_damages_per_m","full_percentile_quality_total",' +
            '"full_percentile_v_score_v2","full_percentile_anomaly"], "conditions": [{"column":"road_name","value":"' + road + '"},' +
            '{"column":"city","value":"' + city + '"}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';

        try {
            const fullResponse = await fetchData(endpoint, fullBody);
            if (fullResponse === null) {
                console.log("Anfrage null");
                const reducedResponse = await fetchData(endpoint, reducedBody);
                reducedResponse.forEach(row => {
                    row.full_percentile_time_loss = 0.00;
                    row.full_percentile_daily_traffic = 0.00;
                });
                console.log(reducedResponse);
                return reducedResponse;
            }
            console.log("Volle Anfrage");
            console.log(fullResponse);
            return fullResponse;
        } catch (error) {
            const reducedResponse = await fetchData(endpoint, reducedBody);
            reducedResponse.forEach(row => {
                row.full_percentile_time_loss = 0.00;
                row.full_percentile_daily_traffic = 0.00;
            });
            console.log("Reduzierte Anfrage");
            console.log(reducedResponse);
            return reducedResponse;
        }
    }

    //nur Zeitverlust und Verkehrsaufkommen, die bei vielen Straßen noch null sind
    async fetchReducedRoadScoreStreet(road, city) {
        const body = '{"columns": ["full_percentile_time_loss",' +
            '"full_percentile_daily_traffic"], "conditions": [{"column":"road_name","value":"' + road + '"},' +
            '{"column":"city","value":"' + city + '"}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchRoadScoreStreetTop10() {
        const body = '{"columns": ["road_name","city","full_percentile_score","full_percentile_accidents_per_m",' +
            '"full_percentile_concerns_per_m","full_percentile_damages_per_m","full_percentile_quality_total",' +
            '"full_percentile_v_score_v2","full_percentile_anomaly","full_percentile_time_loss",' +
            '"full_percentile_daily_traffic"], "conditions": [],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchRoadNames() {
        const body = '{"columns": [],"conditions": [],"group_by": [{"column":"city"},{"column":"road_name"}]}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    /*---------------------Accidents-----------------------------------------------------*/
    /*---------------------Map-------------*/

    async fetchAccidentsGeoData(startDate, endDate, quarter1, quarter2, latMax, lngMax, latMin, lngMin) {
        if (quarter1 === quarter2 || quarter2 === "00:00:00") {
            quarter1 = "00:00:00"
            quarter2 = "23:59:00";
        }
        const body = '{"columns": ["euska_accident_type", "euska_accident_type_long","euska_accident_type_simplified",' +
            '"euska_accident_color","euska_road_condition","cognos_accident_type","injured","euska_participant_german",' +
            '"number_participants","perpetrator_german","serious_injured","slightly_injured","killed","cognos_monetary_damage",' +
            '"cognos_alcohol","cognos_drugs","cloudcover","temp","heatindex","dew","solarenergy","humidity","snow","snowdepth",' +
            '"road_name","condition","datetime","euska_longitude","euska_latitude"], "conditions": [{"column":"euska_latitude",' +
            '"operator": "<=", "value": ' + latMax + '},{"column":"euska_latitude", "operator": ">=", "value": ' + latMin + '},' +
            '{"column":"euska_longitude", "operator": "<=", "value": ' + lngMax + '},{"column":"euska_longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '},{"operator": ">=", "value": "' + startDate + '" }, {"operator": "<=", ' +
            '"value": "' + endDate + '"},{"datetime": "quarter", "operator": ">=", "value": "' + quarter1 + '"},' +
            '{"datetime": "quarter", "operator": "<=", "value": "' + quarter2 + '"}],"group_by": []}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentsGeoDataBounded(startDate, endDate, quarter1, quarter2, latMax, lngMax, latMin, lngMin) {
        console.log(startDate)
        if (quarter1 === quarter2 || quarter2 === "00:00:00") {
            quarter1 = "00:00:00"
            quarter2 = "23:59:00";
        }
        const body = '{"columns": ["euska_accident_type", "euska_accident_type_long","euska_accident_type_simplified",' +
            '"euska_accident_color","euska_road_condition","cognos_accident_type","injured","euska_participant_german",' +
            '"number_participants","perpetrator_german","serious_injured","slightly_injured","killed","cognos_monetary_damage",' +
            '"cognos_alcohol","cognos_drugs","cloudcover","temp","heatindex","dew","solarenergy","humidity","snow","snowdepth",' +
            '"road_name","condition","datetime","euska_longitude","euska_latitude"], "conditions": [{"column": "euska_longitude",' +
            '"operator": ">","value":"0"},{"operator": ">=", "value": "' + startDate + '" }, {"operator": "<=", ' +
            '"value": "' + endDate + '"},{"datetime": "quarter", "operator": ">=", "value": "' + quarter1 + '"},' +
            '{"datetime": "quarter", "operator": "<=", "value": "' + quarter2 + '"},{"column":"euska_latitude", "operator": "<=", "value": ' + latMax + '}, {"column":"euska_latitude", "operator": ">=", ' +
            '"value": ' + latMin + '}, {"column":"euska_longitude", "operator": "<=", "value": ' + lngMax + '}, ' +
            '{"column":"euska_longitude", "operator": ">=", "value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }


    async fetchAccidentsCountStreet(city, sD, eD) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"},' +
            '{ "operator": ">=", "value": "' + sD + '" }, { "operator": "<=", "value": "' + eD + '"}],' +
            '"group_by": [{ "column": "road_name" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }


    /*---------------------Recharts-------------*/

    async fetchAccidentTypes(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "euska_accident_type_simplified" }, { "column": "euska_accident_color_simplified" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchInjuredCountRoadCondition(city, timespan) {
        const body = '{ "columns": {"column":"injured","aggregation":"count"}, "conditions": [{"column": "city", ' +
            '"value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", ' +
            '"value": "' + timespan[1] + '"}], "group_by": [ {"column":"euska_road_condition"}] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentGround(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "euska_road_condition" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentWeatherMonth(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "month" },{ "column": "condition" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentWeather(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [{ "column": "condition" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTempLowerMinus10(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": "<", "value":"-10"}], "group_by": [ { "column": "month" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }


    async fetchAccidentTempMinus10to0(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": "<", "value":"0"},{ "column": "temp","operator": ">", "value":"-10"}], ' +
            '"group_by": [ { "column": "month" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTemp0to10(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": ">=", "value":"0"},{ "column": "temp","operator": "<=", "value":"10"}], ' +
            '"group_by": [ { "column": "month" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTemp10to20(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": ">", "value":"10"},{ "column": "temp","operator": "<=", "value":"20"}], ' +
            '"group_by": [ { "column": "month" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTemp20to30(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": ">", "value":"20"},{ "column": "temp","operator": "<=", "value":"30"}], ' +
            '"group_by": [ { "column": "month" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTempHigher30(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"},' +
            '{ "column": "temp","operator": ">", "value":"30"}], "group_by": [ { "column": "month" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentTime(city, time, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "' + time + '" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentCountPeopleTime(city, time, timespan) {
        const body = '{ "columns": [ { "column": "number_participants", "aggregation": "sum" } ], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"} ], "group_by": [ { "column": "' + time + '" }, ' +
            '{ "column": "euska_accident_type_simplified"}] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentCountPeopleType(city, timespan) {
        const body = '{ "columns": [ { "column": "number_participants", "aggregation": "sum" } ], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"} ], "group_by": [ { "column": "euska_accident_type_simplified" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAccidentPerpetrator(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "perpetrator_german" }] }';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchMoneyDamagePerAccidentPerpetrator(city, timespan) {
        const body = '{ "columns": [{"column": "cognos_monetary_damage", "aggregation": "sum"}], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, {"operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{"operator": "<=", "value": "' + timespan[1] + '"}],"group_by": [{"column": "perpetrator_german" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchDamagePerAccidentPerpetrator(city, timespan) {
        const body = '{ "columns": [{"column": "cognos_monetary_damage", "aggregation": "sum"},{"column": "slightly_injured", "aggregation": "sum"},' +
            '{"column": "serious_injured", "aggregation": "sum"},{"column": "killed", "aggregation": "sum"},{"column": "number_participants", "aggregation": "count"}], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, {"operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{"operator": "<=", "value": "' + timespan[1] + '"}],"group_by": [{"column": "perpetrator_german" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchAvgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(city, timespan) {
        const body = '{ "columns": [{"column": "cognos_monetary_damage", "aggregation": "sum"},{"column": "number_participants", "aggregation": "count"}], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, {"operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{"operator": "<=", "value": "' + timespan[1] + '"}],"group_by": [{"column": "perpetrator_german" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchMoneyDamagePerAccidentPerpetratorPie(city, timespan) {
        const body = '{ "columns": [{"column": "cognos_monetary_damage", "aggregation": "count"}], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, {"operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{"operator": "<=", "value": "' + timespan[1] + '"}],"group_by": [{"column": "perpetrator_german" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    async fetchDamagePerAccidentByYear(city) {
        const body = '{ "columns": [{"column": "cognos_monetary_damage", "aggregation": "sum"},{"column": "slightly_injured", "aggregation": "sum"},' +
            '{"column": "serious_injured", "aggregation": "sum"},{"column": "killed", "aggregation": "sum"},{"column": "number_participants", "aggregation": "count"}], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}],"group_by": [{"column": "perpetrator_german" }]}';
        const endpoint = domain + 'accidents';
        return fetchData(endpoint, body);
    }

    /*---------------------Weather-----------------------------------------------------*/

    async fetchWeatherCondition(city, timespan) {
        const body = '{ "columns": [{ "column": "condition" }, { "column": "datetime" }], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}]}';
        const endpoint = domain + 'weather';
        return fetchData(endpoint, body);
    }

    async fetchTemperatureCondition(city, timespan) {
        const body = '{ "columns": [{ "column": "temp" }, { "column": "datetime" }], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}]}';
        const endpoint = domain + 'weather';
        return fetchData(endpoint, body);
    }

    /*---------------------Rides-----------------------------------------------------*/

    async fetchRidesConditions(city, timespan) {
        const body = '{ "columns": ["average_speed", "length", "condition"], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"}]}';
        const endpoint = domain + 'rides_information';
        return fetchData(endpoint, body);
    }

    async fetchRidesTemperatures(city, timespan) {
        const body = '{ "columns": ["average_speed", "length", "temp"], ' +
            '"conditions": [{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"}]}';
        const endpoint = domain + 'rides_information';
        return fetchData(endpoint, body);
    }

    /*---------------------BIQEMonitor-----------------------------------------------------*/

    async fetchRoadsData(column, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["' + column + '", "geometry"], "conditions": [{"column":"latitude", ' +
            '"operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchRoaddata() {
        const endpoint = domain + 'roads';
        return fetchData(endpoint);
    }

    async fetchRoadsBounded(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'roads/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;;
        return fetchData(endpoint);
    }

    async fetchRoadSegments() {
        const endpoint = domain + 'road_segments';
        return fetchData(endpoint);
    }

    async fetchRoadSegmentsBounded(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'road_segments/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;
        return fetchData(endpoint);
    }

    /*Straßeneinfärbungen*/
    async fetchRoadsData(column, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["' + column + '", "geometry"], "conditions": [{"column":"latitude", ' +
            '"operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    async fetchRoadswithAccidentsData(latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["road_name","accidents", "length", "geometry"], "conditions": [{"column":"latitude", ' +
            '"operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    /*Heatmaps*/
    async fetchHeatmapData(column, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["' + column + '", "geometry"], "conditions": [{"column":"latitude", ' +
            '"operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_grids';
        return fetchData(endpoint, body);
    }

    /*Knotenpunkte*/
    async fetchNodesData(column, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["' + column + '", "geometry"], "conditions": [{"column":"latitude", ' +
            '"operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }


    async fetchSurfaceConditionRoadSegmentsData() {
        const body = '{"columns": ["surface_condition", "geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_road_segments';
        return fetchData(endpoint, body);
    }

    /*---------------------Kumulierte Segmentwerte-----------------------------------------------------*/

    async fetchOnlyNode() {
        const body = '{"columns": ["geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }


    async fetchAccidentsPerNode() {
        const body = '{"columns": ["accidents", "geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }

    async fetchConcernsPerNode() {
        const body = '{"columns": ["concerns", "geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }

    async fetchDamagesPerNode() {
        const body = '{"columns": ["damages", "geometry"], "conditions": [], "group_by": []}';
        const endpoint = domain + 'biqemonitor_nodes';
        return fetchData(endpoint, body);
    }

    /*---------------------Bus-----------------------------------------------------*/

    async fetchBusLines(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'bus_lines/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;
        return fetchData(endpoint);
    }

    async fetchBusStops(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'bus_stop/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;
        return fetchData(endpoint);
    }

    /*---------------------Road Geometry-----------------------------------------------------*/
    async fetchGeometryRoad(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'geometry_road/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;
        return fetchData(endpoint);
    }

    async fetchRoadFeatures(latMax, lngMax, latMin, lngMin) {
        const endpoint = domain + 'road_infrastructure_features/long_min/lat_min/long_max/lat_max?long_min=' + lngMin +
            '&lat_min=' + latMin + '&long_max=' + lngMax + '&lat_max=' + latMax;
        return fetchData(endpoint);
    }

    /*---------------------Images-----------------------------------------------------*/

    async fetchImageGeoData() {
        const body = '{"columns": ["annotation_id", "image_id", "image_path_raw","image_path_detect", "longitude","latitude", ' +
            '"upload_date", "download_url"]}'
        const endpoint = domain + 'image';
        return fetchData(endpoint, body);
    }

    async fetchImageGeoDataRecharts() {
        const body = '{"columns": ["count"], "conditions": [],"group_by": [{"column":"classification"}]}'
        const endpoint = domain + 'image';
        return fetchData(endpoint, body);
    }

    async fetchImageGeoDataForDashboard(startDate, endDate, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["annotation_id", "classification","confidence","create_date","longitude","latitude"], ' +
            '"conditions": [{"operator": "<=", "value": ' + endDate + '},{"operator": ">=", "value": ' + startDate + '},' +
            '{"column":"latitude", "operator": "<=", "value": ' + latMax + '},{"column":"latitude", "operator": ">=", ' +
            '"value": ' + latMin + '},{"column":"longitude", "operator": "<=", "value": ' + lngMax + '},' +
            '{"column":"longitude", "operator": ">=", "value": ' + lngMin + '}]}'
        const endpoint = domain + 'image';
        return fetchData(endpoint, body);
    }

    async fetchRoadDamageMessages(latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["annotation_id", "classification","confidence","upload_date","longitude","latitude"],' +
            '"conditions": [{ "column": "latitude", "operator": "<=", "value": "' + latMax + '" },' +
            '{ "column": "latitude", "operator": ">=", "value": "' + latMin + '" },' +
            '{ "column": "longitude", "operator": "<=", "value": "' + lngMax + '" },' +
            '{ "column": "longitude", "operator": ">=", "value": "' + lngMin + '" }' +
            ']}'
        const endpoint = domain + 'image';
        return fetchData(endpoint, body);
    }

    /*---------------------CountingStations-----------------------------------------------------*/
    /*---------------------Bike-------------*/

    async fetchBikeTraffic(time, road_name, timespan) {
        const body = '{ "columns": [ { "column": "countTo", "aggregation": "sum" }, { "column": "countFrom", ' +
            '"aggregation": "sum" }, { "column": "bothDirections", "aggregation": "sum" }, "count" ], ' +
            '"conditions": [{"column": "road_name", "value": "' + road_name + '"}, { "operator": ">=", ' +
            '"value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "' + time + '" }] }';
        const endpoint = domain + 'bicycle_volume';
        return fetchData(endpoint, body);
    }

    async fetchStationsGeoDataTimeRange(sD, eD, q1, q2, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": [{ "column": "countTo", "aggregation": "sum" }, { "column": "countFrom", ' +
            '"aggregation": "sum" }, { "column": "bothDirections", "aggregation": "max" }, { "column": "bothDirections", ' +
            '"aggregation": "sum" }, "count" ], "conditions": [{"operator": ">=", "value": "' + sD + '" }, ' +
            '{"operator": "<=", "value": "' + eD + '"},{"datetime": "quarter", "operator": ">=", "value": "' + q1 + '"},' +
            '{"datetime": "quarter", "operator": "<=", "value": "' + q2 + '"}, {"column":"latitude", "operator": "<=", ' +
            '"value": ' + latMax + '},{"column":"latitude", "operator": ">=", "value": ' + latMin + '},{"column":"longitude", ' +
            '"operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", "value": ' + lngMin + '}],' +
            '"group_by": [{"column": "station_id"}, {"column": "road_name"},{"column": "city"}, {"column": "latitude"}, {"column": "longitude"}]}'
        const endpoint = domain + 'bicycle_volume';
        return fetchData(endpoint, body);
    }

    async fetchStationsGeoData15min(latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": [{ "column": "countTo", "aggregation": "sum" }, { "column": "countFrom", ' +
            '"aggregation": "sum" }, { "column": "bothDirections", "aggregation": "max" }, { "column": "bothDirections", ' +
            '"aggregation": "sum" },{"column": "bothDirections", "aggregation": "day_avg"},{"column": "bothDirections", ' +
            '"aggregation": "hour_avg"},{"column": "countTo", "aggregation": "day_avg"},{"column": "countTo", ' +
            '"aggregation": "hour_avg"},{"column": "countFrom", "aggregation": "day_avg"},{"column": "countFrom", ' +
            '"aggregation": "hour_avg"}, "count" ], "conditions": [{"column":"latitude", "operator": "<=", ' +
            '"value": ' + latMax + '},{"column":"latitude", "operator": ">=", ' +
            '"value": ' + latMin + '},{"column":"longitude", "operator": "<=", "value": ' + lngMax + '},' +
            '{"column":"longitude", "operator": ">=", "value": ' + lngMin + '}],"group_by": [{"column": "station_id"}, ' +
            '{"column": "road_name"},{"column": "city"}, {"column": "latitude"}, {"column": "longitude"}]}'
        const endpoint = domain + 'bicycle_volume';
        return fetchData(endpoint, body);
    }

    async fetchStationsGeoDataJahreswerte(latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": [{"column": "sum_bothDirections", "aggregation": "avg"},{"column": ' +
            '"day_peak_bothDirections_time", "aggregation": "max"},{"column": "day_peak_countFrom_time", "aggregation": ' +
            '"max"}, {"column": "day_peak_countTo_time", "aggregation": "max"},{"column": "dow_peak_bothDirections_time", ' +
            '"aggregation": "max"}], "conditions": [{"column":"latitude", "operator": "<=", "value": ' + latMax + '},' +
            '{"column":"latitude", "operator": ">=", "value": ' + latMin + '},{"column":"longitude", "operator": "<=", "value": ' + lngMax + '},' +
            '{"column":"longitude", "operator": ">=", "value": ' + lngMin + '}],"group_by": [{"column": "station_id"}, {"column": "road_name"},' +
            '{"column": "city"}, {"column": "latitude"}, {"column": "longitude"}]}'
        const endpoint = domain + 'peak_bicycle_volume';
        return fetchData(endpoint, body);
    }

    async fetchBikeTrafficAllStreets(city, timespan) {
        const body = '{ "columns": [ { "column": "bothDirections", "aggregation": "sum" }, { "column": "countTo", ' +
            '"aggregation": "sum" }, { "column": "countFrom", "aggregation": "sum" }, "count"], "conditions": ' +
            '[{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"}], "group_by": [ { "column": "road_name" }, { "column": "latitude" }, { "column": "longitude" }] }';
        const endpoint = domain + 'bicycle_volume';
        return fetchData(endpoint, body);
    }

    async fetchBikeStations() {
        const endpoint = domain + 'bicycle_volume_stations';
        return fetchData(endpoint);
    }

    /*---------------------Car-------------*/

    async fetchCarStations(startDate, endDate, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": [{"column":"traffic_volume", "aggregation": "max"},{"column":"traffic_volume", ' +
            '"aggregation": "day_avg"},{"column":"traffic_volume", "aggregation": "hour_avg"},{"column":"traffic_volume", ' +
            '"aggregation": "sum"}, {"column":"speed", "aggregation": "max"},{"column":"speed", "aggregation": "avg"}],' +
            '"conditions": [{"operator": ">=", "value": "' + startDate + '" }, {"operator": "<=", "value": "' + endDate + '"}, ' +
            '{"column":"latitude", "operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", "value": ' + latMin + '}, ' +
            '{"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, {"column":"longitude", "operator": ">=", ' +
            '"value": ' + lngMin + '}],"group_by": [{"column":"station_id"},{"column":"longitude"},{"column":"latitude"}]}'
        const endpoint = domain + 'traffic_volume';
        return fetchData(endpoint, body);
    }

    /*---------------------Concerns-----------------------------------------------------*/
    /*---------------------Map-------------*/

    async fetchConcernsGeoData(startDate, endDate, latMax, lngMax, latMin, lngMin) {
        const body = '{"columns": ["topic_text","category_text","status_text","description","latitude","longitude",' +
            '"datetime","day","month","year", "mint", "heatindex","cloudcover", "snow"],"conditions": [{"operator": ">=", ' +
            '"value": "' + startDate + '" }, {"operator": "<=", "value": "' + endDate + '"}, ' +
            '{"column":"latitude", "operator": "<=", "value": ' + latMax + '}, {"column":"latitude", "operator": ">=", ' +
            '"value": ' + latMin + '}, {"column":"longitude", "operator": "<=", "value": ' + lngMax + '}, ' +
            '{"column":"longitude", "operator": ">=", "value": ' + lngMin + '}],"group_by": []}';
        const endpoint = domain + 'concerns';
        return fetchData(endpoint, body);
    }

    /*---------------------Recharts-------------*/

    async fetchConcernsType(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "category_text" }] }';
        const endpoint = domain + 'concerns';
        return fetchData(endpoint, body);
    }

    async fetchConcernsTypeProblem(city, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "topic_text" }] }';
        const endpoint = domain + 'concerns';
        return fetchData(endpoint, body);
    }

    async fetchConcernsTime(city, time, timespan) {
        const body = '{ "columns": ["count"], "conditions": [{"column": "city", "value": "' + city + '"}, ' +
            '{ "operator": ">=", "value": "' + timespan[0] + '" }, { "operator": "<=", "value": "' + timespan[1] + '"}], ' +
            '"group_by": [ { "column": "' + time + '" }] }';
        const endpoint = domain + 'concerns';
        return fetchData(endpoint, body);
    }

    async fetchConcernsDuration(city, timespan) {
        const body = '{ "columns": [ { "column": "duration", "aggregation": "avg" } ], "conditions": ' +
            '[{"column": "city", "value": "' + city + '"}, { "operator": ">=", "value": "' + timespan[0] + '" }, ' +
            '{ "operator": "<=", "value": "' + timespan[1] + '"}] , "group_by": [ { "column": "category_text" }] }';
        const endpoint = domain + 'concerns';
        return fetchData(endpoint, body);
    }
}
