import React, { useState } from "react";
import { Offcanvas, Button, ButtonGroup } from "react-bootstrap";

const NavComponent = ({
  handleLoadSegments,
  setFilterModalOpen,
  toggleMultiSelectMode,
  toggleOffcanvas,
  setExportModalOpen,
  setImportModalOpen,
  multiSelectMode,
  selectedSegments,
  navShow,
  handleNavShow,
  setModalOpen
}) => {

  return (
    <Offcanvas show={navShow} onHide={handleNavShow} placement="start" className="greyOC">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Optionen</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="switch-container">
          <p>Lade eine Datenquelle:</p>
          <ButtonGroup className="mb-3">
            <Button onClick={() => handleLoadSegments('overpass')}>
              Overpass API
            </Button>
            <Button onClick={() => handleLoadSegments('fastapi')}>
              FastAPI
            </Button>
            <Button onClick={() => handleLoadSegments('imported')}>
              Importierte Segmente
            </Button>
          </ButtonGroup>
        </div>
        <hr></hr>

        <button className="test-button" onClick={() => setFilterModalOpen(true)}>
          Segmente filtern
        </button>

        <button
          className="test-button"
          onClick={toggleMultiSelectMode}
        >
          {multiSelectMode ? "Multi-Auswahl beenden" : "Multi-Auswahl starten"}
        </button>

        <button
          className="test-button"
          onClick={toggleOffcanvas}
        >
          Einträge anzeigen/ändern
        </button>

        <button
          className="test-button"
          onClick={() => setExportModalOpen(true)}
        >
          Exportieren
        </button>

        <button
          className="test-button"
          onClick={() => setImportModalOpen(true)}
        >
          GeoJson importieren
        </button>

        {multiSelectMode && selectedSegments.size > 0 && (
          <button
            className="test-button"
            onClick={() => setModalOpen(true)}
          >
            Eintrag für Auswahl
          </button>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NavComponent;