import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {fetchHeatmapData} from "../../functions/MapFunctions";
import {getHeatmapProperties, getValue} from "../../functions/Translations";

function HeatmapsLayer({roadState, heatmapsChecked, heatmapData, mapBounds, loadingSpin,
                           updateHeatmapData, updateLoadingSpin}) {

    const heatmapProperties = getHeatmapProperties();
    const property = heatmapProperties[roadState];

    const useHeatmapData = useCallback(() => {
        fetchHeatmapData(property, mapBounds, updateHeatmapData, updateLoadingSpin);
    }, [mapBounds, property]);


    useEffect(() => {
        if (heatmapsChecked) {
            useHeatmapData();
        }
    }, [mapBounds, property, heatmapsChecked]);


    const layers = [
        {state: "AnomalieV1", tooltip: "Anomalien: "},
        {state: "AnomalieV2", tooltip: "Anomalien (inkl. Geschwindigkeit): "},
        {state: "FrequencyRoad", tooltip: "Verkehrsstärke: "},
        {state: "V1Road", tooltip: "Erschütterungen: "},
        {state: "V2Road", tooltip: "Erschütterungen (inkl. Geschwindigkeit): "},
        {state: "WaitingTimeRoad", tooltip: "Zeitverlust: "},
        {state: "NormSpeedRoad", tooltip: "Norm. Geschwindigkeit: "},
        {state: "AbsoluteSpeedRoad", tooltip: "Abs. Geschwindigkeit: "},
        {state: "FullBrakings", tooltip: "Vollbremsungen: "}
    ]

    return (
        <LayersControl.Overlay name="4: Heatmaps" checked={heatmapsChecked}>
            <LayerGroup>
                {layers.map((layer) => (
                    <>
                        {roadState === layer.state && !loadingSpin && heatmapData.length > 0 && (
                            <>
                                {heatmapData.map((road, index) => {
                                    const interpolate = require('color-interpolate');
                                    let colormap = interpolate(['green', "yellow", 'red']);
                                    const uniqueKey = `${layer.state}_${index}`;
                                    const value = getValue(road, layer.state);

                                    return (
                                        <Polyline key={uniqueKey} positions={road.geometry.coordinates}
                                                  color={colormap(value)} weight={1} fill={true}
                                                  opacity={0.3}>
                                            <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                                {layer.tooltip + ((value === null) ? 0 :
                                                    value.toFixed(4)) + " | Gut [0;1] Schlecht"}
                                            </Tooltip>
                                        </Polyline>
                                    );
                                })}
                            </>
                        )}
                    </>
                ))}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default HeatmapsLayer;