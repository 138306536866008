import React, { useCallback, useEffect } from "react";
import { LayerGroup, LayersControl, Polyline, Tooltip } from "react-leaflet";
import { onlyRoadScoreCol } from "../../functions/MapFunctions";
import { getScoreStateProperties } from "../../functions/Translations";
import { getColorClass } from "../../functions/Colors";

function RoadScoreLayer({ scoreState, roadScoreChecked, roadScoreData, mapBounds, loadingSpin,
                            updateRoadScoreData, updateLoadingSpin }) {
    const scoreStateProperties = getScoreStateProperties();

    const useRoadScore = useCallback(() => {
        const property = scoreStateProperties[scoreState];
        onlyRoadScoreCol(property, mapBounds, updateRoadScoreData, updateLoadingSpin);
    }, [mapBounds, scoreState]);

    useEffect(() => {
        if (roadScoreChecked) {
            useRoadScore();
        }
    }, [mapBounds, scoreState, roadScoreChecked]);

    return (
        <>
            <LayersControl.Overlay name="1: Straßenscore" checked={roadScoreChecked}>
                <LayerGroup>
                    {/* Straßen-Score anzeigen */}
                    {scoreState === "Score" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];

                        if (!road || road.full_percentile_score === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }

                        return (
                            <Polyline positions={road.geometry.coordinates} color={getColorClass(road.full_percentile_score)}
                                weight={2} fill={false} opacity={1} key={i}> {/* 1 for visible, 0 for hidden */}
                                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                    {"Roadscore: " + (road.full_percentile_score ? road.full_percentile_score.toFixed(2) : "k.A")} <br></br>
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Unfälle anzeigen */}
                    {scoreState === "Unfälle" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_accidents_per_m === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_accidents_per_m)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Unfälle: " + (road.full_percentile_accidents_per_m ? road.full_percentile_accidents_per_m.toFixed(2) : "k.A")}<br></br>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Meldungen anzeigen */}
                    {scoreState === "Meldungen" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_concerns_per_m === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_concerns_per_m)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Meldungen: " + (road.full_percentile_concerns_per_m ? road.full_percentile_concerns_per_m.toFixed(2) : "k.A")}<br></br>


                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Verkehrsaufkommen anzeigen */}
                    {scoreState === "Verkehrsaufkommen" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_daily_traffic === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_daily_traffic)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Verkehrsaufkommen: " + (road.full_percentile_daily_traffic ? road.full_percentile_daily_traffic.toFixed(2) : "k.A")}<br></br>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Zeitverlust anzeigen */}
                    {scoreState === "Zeitverlust" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_time_loss === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_time_loss)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Zeitverlust: " + (road.full_percentile_time_loss ? road.full_percentile_time_loss.toFixed(2) : "k.A")}<br></br>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Zeitverlust anzeigen */}
                    {scoreState === "Anomalie" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_anomaly === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_anomaly)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Anomalie: " + (road.full_percentile_anomaly ? road.full_percentile_anomaly.toFixed(2) : "k.A")}<br></br>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Erschütterungen anzeigen */}
                    {scoreState === "Erschütterungen" && !loadingSpin && roadScoreData.length > 0 && Object.keys(roadScoreData).map((i) => {
                        const road = roadScoreData[i];
                        if (!road || road.full_percentile_v_score_v2 === undefined) {
                            return null; // Überspringe dieses Element, wenn Daten fehlen
                        }
                        return (
                            <Polyline
                                key={i}
                                positions={road.geometry.coordinates}
                                color={getColorClass(road.full_percentile_v_score_v2)}
                                weight={2}
                                fill={false}
                                opacity={1} // 1 for visible, 0 for hidden
                            >
                                <Tooltip
                                    sticky={true}
                                    direction="right"
                                    offset={[0, 5]}
                                >
                                    {"Straße: " + (road.road_name ? road.road_name : "k.A")}<br></br>
                                    {"Erschütterungen: " + (road.full_percentile_v_score_v2 ? road.full_percentile_v_score_v2.toFixed(2) : "k.A")}<br></br>

                                </Tooltip>
                            </Polyline>
                        );
                    })}

                </LayerGroup>
            </LayersControl.Overlay>
        </>
    );
}

export default RoadScoreLayer;