import React, { useEffect } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import ApiConnection from "../dashboard/api/ApiConnection";

export const ApiConnect = new ApiConnection();

const FastApiSegment = ({
  segment,
  mapBounds,
  setSegments,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments
}) => {
  useEffect(() => {
    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;
      ApiConnect.fetchGeometryRoad(latMax, lngMax, latMin, lngMin)
      .then((data) => {
          const newData = {};
          console.log(data);
          if (data !== null) {
              data.forEach(item => {
                  const { geometry_id, slope, path_type, geometry, ...rest } = item;

                  if (slope !== null || path_type !== null) {

                      let coordinates = geometry.coordinates;
                      coordinates.forEach(function (value, i) {
                          coordinates[i] = [value[1], value[0]];
                      });
                      geometry.coordinates = coordinates;

                      newData[geometry_id] = { ...(newData[geometry_id] || {}), slope, path_type, geometry, ...rest }
                  }
              });
          }
          console.log(newData);
          setSegments(newData);
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Segmente:", error);
        });
    }
  }, [mapBounds, setSegments]);

  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };

  return (
    <>
      {Object.keys(segment).map((i) => {
        const road = segment[i];
        const isSelected = selectedSegments.has(road.road_id);
        if (road.path_type !== undefined && road.slope !== undefined) {
          return (
            <React.Fragment key={road.road_id}>
              <Polyline
                key={`${road.road_id}-white`}
                positions={road.geometry.coordinates}
                color="white"
                weight={8}
                fill={false}
                opacity={1}
              />
              <Polyline
                key={i}
                positions={road.geometry.coordinates}
                className="hoversegment"
                color={isSelected ? "orange" : "blue"}
                weight={6}
                fill={false}
                opacity={0.8}
                eventHandlers={{
                  click: () => handlePolylineClick(road.segment_id),
                  mouseover: (e) => {
                    e.target.setStyle({ color: '#6c9c34', opacity: 1.0 });
                  },
                  mouseout: (e) => {
                    e.target.setStyle({ color: isSelected ? "orange" : "blue", opacity: 0.8 });
                  }
                }}
              >
                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                  <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {road.segment_id && (
                        <tr>
                          <td><strong>ID</strong></td>
                          <td>{road.road_id}</td>
                        </tr>
                      )}
                      {road.slope && (
                        <tr>
                          <td><strong>Steigung</strong></td>
                          <td>{(road.slope.toFixed(2)) + "%"}</td>
                        </tr>
                      )}
                      {road.path_type && (
                        <tr>
                          <td><strong>Führungsform</strong></td>
                          <td>{road.path_type}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Tooltip>
              </Polyline>
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
};

export default FastApiSegment;