import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getGermanWordBiqemonitor } from './Translations';

const Legende = ({
  roadScoreChecked,
  nodeChecked,
  roadSegmentsChecked,
  heatmapsChecked,
  roadGeometryChecked,
  infrastructureChecked,
  scoreState,
  nodeState,
  roadSegmentState,
  roadState,
  roadGeometryState,
  scoreStateInfrastructure,
  accidentRateData,
  mergedRoadData,
  accidentRoadData,
  accidentNodeData,
  trafficRoadData,
  concernRoadData,
  roadDamageData
}) => {
  const [currentLegendIndex, setCurrentLegendIndex] = useState(0);

  const getLegendConfig = () => {
    const colors = ['#437c17', '#a4c02c', '#dce738', '#f8e53a', '#eeac2e', '#db4918'];
    const config = [];

    if (roadScoreChecked) {
      config.push({
        title: 'Straßenscore (Perzentil)',
        subtitle: `Kategorie: ${getGermanWordBiqemonitor(scoreState)} <=`,
        ticks: [0.00, 0.30, 0.50, 0.80, 0.92, 1.00],
        colors,
        tickFormat: (value) => `${value}`,
        numberType: 'double',
      });
    }
    if (nodeChecked) {
      config.push({
        title: 'Knotenwerte',
        subtitle: `Kategorie: ${getGermanWordBiqemonitor(nodeState)}, Grenze: <=`,
        ticks: [0.00, 0.30, 0.50, 0.80, 0.92, 1.00],
        colors,
        tickFormat: (value) => `${value}`,
        numberType: 'double',
      });
    }
    if (roadSegmentsChecked) {
      config.push({
        title: 'Straßenabschnitte',
        subtitle: `Kategorie: ${getGermanWordBiqemonitor(roadSegmentState)} <=`,
        ticks: [0.00, 0.30, 0.50, 0.80, 0.92, 1.00],
        colors,
        tickFormat: (value) => `${value}`,
        numberType: 'double',
      });
    }
    if (heatmapsChecked) {
      config.push({
        title: 'Heatmaps',
        subtitle: `Kategorie: ${getGermanWordBiqemonitor(roadState)}, Grenze: <=`,
        ticks: [0.00, 0.30, 0.50, 0.80, 0.92, 1.00],
        colors,
        tickFormat: (value) => `${value}`,
        numberType: 'double',
      });
    }
    if (roadGeometryChecked && roadGeometryState === "Slope") {
      config.push({
        title: 'Steigungsgrad',
        subtitle: "Steigung bis in %",
        ticks: [0.15, 1.00, 3.00, 5.00, 8.00, 15.00],
        colors,
        tickFormat: (value) => `${value}%`,
        numberType: 'double',
      });
    }
    if (infrastructureChecked) {
      if (scoreStateInfrastructure !== "Verkehrsaufkommen" && scoreStateInfrastructure !== "Geschwindigkeit" && scoreStateInfrastructure !== "Unfallrate") {
        config.push({
          title: 'Segmentwerte (absolut)',
          subtitle: `Kategorie: ${scoreStateInfrastructure}`,
          ticks: [0, 1, 3, 5, 15, 30],
          colors,
          tickFormat: (value) => `${value}`,
          numberType: 'int',
        });
      }
      if (trafficRoadData && trafficRoadData[0] && scoreStateInfrastructure === "Verkehrsaufkommen") {
        const Traffic00 = (trafficRoadData[0].max * 0.00).toFixed(0);
        const Traffic10 = (trafficRoadData[0].max * 0.10).toFixed(0);
        const Traffic40 = (trafficRoadData[0].max * 0.40).toFixed(0);
        const Traffic60 = (trafficRoadData[0].max * 0.60).toFixed(0);
        const Traffic85 = (trafficRoadData[0].max * 0.85).toFixed(0);
        const Traffic95 = (trafficRoadData[0].max * 0.95).toFixed(0);

        config.push({
          title: 'Segmentwerte (absolut)',
          subtitle: `${scoreStateInfrastructure} pro Tag > als`,
          ticks: [Traffic00, Traffic10, Traffic40, Traffic60, Traffic85, Traffic95],
          colors,
          tickFormat: (value) => `${value}`,
          numberType: 'int',
        });
      }
      if (scoreStateInfrastructure === "Geschwindigkeit") {
        config.push({
          title: 'Segmentwerte (absolut)',
          subtitle: `${scoreStateInfrastructure} in km/h größer`,
          ticks: [0, 30, 33, 36, 39, 42],
          colors,
          tickFormat: (value) => `${value}`,
          numberType: 'int',
        });
      }
      if (accidentRateData && accidentRateData[0] && scoreStateInfrastructure === "Unfallrate") {
        const Unfallrate00 = (accidentRateData[0].max * 0.00).toFixed(0);
        const Unfallrate10 = (accidentRateData[0].max * 0.10).toFixed(0);
        const Unfallrate40 = (accidentRateData[0].max * 0.40).toFixed(0);
        const Unfallrate60 = (accidentRateData[0].max * 0.60).toFixed(0);
        const Unfallrate85 = (accidentRateData[0].max * 0.85).toFixed(0);
        const Unfallrate95 = (accidentRateData[0].max * 0.95).toFixed(0);

        config.push({
          title: 'Segmentwerte (absolut)',
          subtitle: `${scoreStateInfrastructure}`,
          ticks: [Unfallrate00, Unfallrate10, Unfallrate40, Unfallrate60, Unfallrate85, Unfallrate95],
          colors,
          tickFormat: (value) => `${value}`,
          numberType: 'double',
        });
      }
    }

    return config;
  };

  const legendConfig = getLegendConfig();

  const previousStep = () => {
    setCurrentLegendIndex((prevIndex) => (prevIndex === 0 ? legendConfig.length - 1 : prevIndex - 1));
  };

  const nextStep = () => {
    setCurrentLegendIndex((prevIndex) => (prevIndex === legendConfig.length - 1 ? 0 : prevIndex + 1));
  };

  if (legendConfig.length === 0) {
    return null;
  }

  const currentLegend = legendConfig[currentLegendIndex] || {};

  const { title, subtitle, ticks, colors, tickFormat, numberType } = currentLegend;

  function dissapear() {
    const legendWrapper = document.querySelector('.legendWrapper');
    const leftArrowSvg = document.querySelector('.ausblenden.left svg');

    if (legendWrapper.style.display === 'none') {
      legendWrapper.style.display = 'flex';
      leftArrowSvg.classList.remove('heightadjust', 'rotated');
    } else {
      legendWrapper.style.display = 'none';
      leftArrowSvg.classList.add('heightadjust', 'rotated');
    }
  }

  return (
    <div className="flexClass">
      <div className="ausblenden left" onClick={dissapear} data-tooltip="Legende ein-/ausblenden">
        <svg xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 448 512">
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </div>

      <div className="legendWrapper">
        <div className="left">
          <div className="titleRow">
            <p className="legendtitle">{title}{" ("}{currentLegendIndex + 1}{"/"}{legendConfig.length}{")"}</p>
            <p className="legendtitle subtitle"> {subtitle}</p>
          </div>

          <div className="legendRow">
            <div className="colorLegend">
              {ticks.map((tick, index) => (
                <div key={index}>
                  <div
                    className="colorBox"
                    style={{
                      backgroundColor: colors[index],
                    }}
                  />
                  <span className="label">{tickFormat ? tickFormat(tick) : tick}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="nextlegend right" data-tooltip="Legende wechseln">
        <svg xmlns="http://www.w3.org/2000/svg" onClick={nextStep} height="1.4em" viewBox="0 0 448 512" >
          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
        </svg>
      </div>
    </div>
  );
};

Legende.propTypes = {
  roadScoreChecked: PropTypes.bool.isRequired,
  nodeChecked: PropTypes.bool.isRequired,
  roadSegmentsChecked: PropTypes.bool.isRequired,
  heatmapsChecked: PropTypes.bool.isRequired,
  roadGeometryChecked: PropTypes.bool.isRequired,
  infrastructureChecked: PropTypes.bool.isRequired,
  scoreState: PropTypes.string,
  nodeState: PropTypes.string,
  roadSegmentState: PropTypes.string,
  roadState: PropTypes.string,
  roadGeometryState: PropTypes.string,
  scoreStateInfrastructure: PropTypes.string,
  accidentRateData: PropTypes.array,
  mergedRoadData: PropTypes.array,
  accidentRoadData: PropTypes.array,
  accidentNodeData: PropTypes.array,
  trafficRoadData: PropTypes.array,
  concernRoadData: PropTypes.array,
  roadDamageData: PropTypes.array,
};

Legende.defaultProps = {
  scoreState: '',
  nodeState: '',
  roadSegmentState: '',
  roadState: '',
  roadGeometryState: '',
  scoreStateInfrastructure: '',
  accidentRateData: [],
  mergedRoadData: [],
  accidentRoadData: [],
  accidentNodeData: [],
  trafficRoadData: [],
  concernRoadData: [],
  roadDamageData: [],
};

export default Legende;