import React, { useCallback, useEffect } from "react";
import { LayerGroup, LayersControl, Polyline, Tooltip } from "react-leaflet";
import { fetchNodesData } from "../../functions/MapFunctions";
import { getNodeStateProperties} from "../../functions/Translations";
import { getColorClass} from "../../functions/Colors";

function NodesLayer({ nodeState, nodeChecked, mapBounds, nodesData, loadingSpin, updateNodesData, updateLoadingSpin }) {

    const nodeStateProperties = getNodeStateProperties();

    const useNodesData = useCallback(() => {
        const property = nodeStateProperties[nodeState];

        fetchNodesData(property, mapBounds, updateNodesData, updateLoadingSpin);
    }, [mapBounds, nodeState]);

    useEffect(() => {
        if (nodeChecked) {
            useNodesData();
        }
    }, [mapBounds, nodeChecked, nodeState]);

    return (
        <LayersControl.Overlay name="2: Knotenwerte" checked={nodeChecked}>
            <LayerGroup>
                {/* v1-Score anzeigen */}
                {nodeState === "V1" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((key) => {
                    const node = nodesData[key];

                    return (
                        <Polyline
                            key={node.node_id}
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={getColorClass(node.v_score_v1)}
                            fillOpacity={1}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen: "}<br></br>
                                {(node.v_score_v1 ? node.v_score_v1.toFixed(2) : 0)}

                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* v2-Score anzeigen */}
                {nodeState === "V2" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((key) => {
                    const node = nodesData[key];

                    return (
                        <Polyline
                            key={node.node_id}
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={getColorClass(node.v_score_v2)}
                            fillOpacity={1}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen unter Berücksichtigung der Geschwindigkeit: "}<br></br>
                                {((node.v_score_v2 === null) ? 0 : node.v_score_v2.toFixed(2))}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* Anomaly-Score anzeigen */}
                {nodeState === "Anomaly" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((key) => {
                    const node = nodesData[key];

                    return (
                        <Polyline
                            key={node.node_id}
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={getColorClass(node.anomaly)}
                            fillOpacity={1}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Anomalien: "}<br></br>
                                {(node.anomaly === null) ? 0 : node.anomaly.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* TimeLoss-Score anzeigen */}
                {nodeState === "TimeLoss" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((key) => {
                    const node = nodesData[key];

                    return (
                        <Polyline
                            key={node.node_id}
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={getColorClass(node.time_loss)}
                            fillOpacity={1}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Zeitverlust: "}
                                {(node.time_loss === null) ? 0 : node.time_loss.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default NodesLayer;